import { SNSClient, PublishCommand } from "@aws-sdk/client-sns";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";

const RECIPIENTS = {
  "contactform": "arn:aws:sns:eu-west-1:590877988961:com-website-contact-form",
  "registerform": "arn:aws:sns:eu-west-1:590877988961:com-website-course-application"
}

export class EmailService {
  private snsClient: SNSClient;

  constructor() {
    const credentials = fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: "eu-west-1" }),
      identityPoolId: "eu-west-1:93c29a9f-97a6-4497-b71e-0dae75743ddd"
    });
    this.snsClient = new SNSClient({
      credentials: credentials,
      region: "eu-west-1"
    });
  }

  async sendEmail(recipient:string, subject:string, message:string) {
    let topic = RECIPIENTS[recipient];
    if (!topic) {
      throw new Error("Invalid recipient");
    } else {
      await this.snsClient.send(new PublishCommand({
        Message: message,
        Subject: subject,
        TopicArn: topic,
      }));
    }
  }
}