import { __assign, __awaiter, __generator, __read, __values } from "tslib";
import { HttpRequest as __HttpRequest } from "@aws-sdk/protocol-http";
import { decorateServiceException as __decorateServiceException, expectNonNull as __expectNonNull, expectString as __expectString, extendedEncodeURIComponent as __extendedEncodeURIComponent, getArrayIfSingleItem as __getArrayIfSingleItem, getValueFromTextNode as __getValueFromTextNode, parseBoolean as __parseBoolean, parseRfc3339DateTime as __parseRfc3339DateTime, } from "@aws-sdk/smithy-client";
import { decodeHTML } from "entities";
import { parse as xmlParse } from "fast-xml-parser";
import { AuthorizationErrorException, BatchEntryIdsNotDistinctException, BatchRequestTooLongException, ConcurrentAccessException, EmptyBatchRequestException, EndpointDisabledException, FilterPolicyLimitExceededException, InternalErrorException, InvalidBatchEntryIdException, InvalidParameterException, InvalidParameterValueException, InvalidSecurityException, KMSAccessDeniedException, KMSDisabledException, KMSInvalidStateException, KMSNotFoundException, KMSOptInRequired, KMSThrottlingException, NotFoundException, OptedOutException, PlatformApplicationDisabledException, ResourceNotFoundException, StaleTagException, SubscriptionLimitExceededException, TagLimitExceededException, TagPolicyException, ThrottledException, TooManyEntriesInBatchRequestException, TopicLimitExceededException, UserErrorException, ValidationException, VerificationException, } from "../models/models_0";
import { SNSServiceException as __BaseException } from "../models/SNSServiceException";
export var serializeAws_queryAddPermissionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryAddPermissionInput(input, context)), { Action: "AddPermission", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryCheckIfPhoneNumberIsOptedOutCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryCheckIfPhoneNumberIsOptedOutInput(input, context)), { Action: "CheckIfPhoneNumberIsOptedOut", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryConfirmSubscriptionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryConfirmSubscriptionInput(input, context)), { Action: "ConfirmSubscription", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryCreatePlatformApplicationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryCreatePlatformApplicationInput(input, context)), { Action: "CreatePlatformApplication", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryCreatePlatformEndpointCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryCreatePlatformEndpointInput(input, context)), { Action: "CreatePlatformEndpoint", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryCreateSMSSandboxPhoneNumberCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryCreateSMSSandboxPhoneNumberInput(input, context)), { Action: "CreateSMSSandboxPhoneNumber", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryCreateTopicCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryCreateTopicInput(input, context)), { Action: "CreateTopic", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryDeleteEndpointCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryDeleteEndpointInput(input, context)), { Action: "DeleteEndpoint", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryDeletePlatformApplicationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryDeletePlatformApplicationInput(input, context)), { Action: "DeletePlatformApplication", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryDeleteSMSSandboxPhoneNumberCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryDeleteSMSSandboxPhoneNumberInput(input, context)), { Action: "DeleteSMSSandboxPhoneNumber", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryDeleteTopicCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryDeleteTopicInput(input, context)), { Action: "DeleteTopic", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryGetEndpointAttributesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryGetEndpointAttributesInput(input, context)), { Action: "GetEndpointAttributes", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryGetPlatformApplicationAttributesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryGetPlatformApplicationAttributesInput(input, context)), { Action: "GetPlatformApplicationAttributes", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryGetSMSAttributesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryGetSMSAttributesInput(input, context)), { Action: "GetSMSAttributes", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryGetSMSSandboxAccountStatusCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryGetSMSSandboxAccountStatusInput(input, context)), { Action: "GetSMSSandboxAccountStatus", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryGetSubscriptionAttributesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryGetSubscriptionAttributesInput(input, context)), { Action: "GetSubscriptionAttributes", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryGetTopicAttributesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryGetTopicAttributesInput(input, context)), { Action: "GetTopicAttributes", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryListEndpointsByPlatformApplicationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryListEndpointsByPlatformApplicationInput(input, context)), { Action: "ListEndpointsByPlatformApplication", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryListOriginationNumbersCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryListOriginationNumbersRequest(input, context)), { Action: "ListOriginationNumbers", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryListPhoneNumbersOptedOutCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryListPhoneNumbersOptedOutInput(input, context)), { Action: "ListPhoneNumbersOptedOut", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryListPlatformApplicationsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryListPlatformApplicationsInput(input, context)), { Action: "ListPlatformApplications", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryListSMSSandboxPhoneNumbersCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryListSMSSandboxPhoneNumbersInput(input, context)), { Action: "ListSMSSandboxPhoneNumbers", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryListSubscriptionsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryListSubscriptionsInput(input, context)), { Action: "ListSubscriptions", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryListSubscriptionsByTopicCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryListSubscriptionsByTopicInput(input, context)), { Action: "ListSubscriptionsByTopic", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryListTagsForResourceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryListTagsForResourceRequest(input, context)), { Action: "ListTagsForResource", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryListTopicsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryListTopicsInput(input, context)), { Action: "ListTopics", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryOptInPhoneNumberCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryOptInPhoneNumberInput(input, context)), { Action: "OptInPhoneNumber", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryPublishCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryPublishInput(input, context)), { Action: "Publish", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryPublishBatchCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryPublishBatchInput(input, context)), { Action: "PublishBatch", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryRemovePermissionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryRemovePermissionInput(input, context)), { Action: "RemovePermission", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_querySetEndpointAttributesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_querySetEndpointAttributesInput(input, context)), { Action: "SetEndpointAttributes", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_querySetPlatformApplicationAttributesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_querySetPlatformApplicationAttributesInput(input, context)), { Action: "SetPlatformApplicationAttributes", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_querySetSMSAttributesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_querySetSMSAttributesInput(input, context)), { Action: "SetSMSAttributes", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_querySetSubscriptionAttributesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_querySetSubscriptionAttributesInput(input, context)), { Action: "SetSubscriptionAttributes", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_querySetTopicAttributesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_querySetTopicAttributesInput(input, context)), { Action: "SetTopicAttributes", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_querySubscribeCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_querySubscribeInput(input, context)), { Action: "Subscribe", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryTagResourceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryTagResourceRequest(input, context)), { Action: "TagResource", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryUnsubscribeCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryUnsubscribeInput(input, context)), { Action: "Unsubscribe", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryUntagResourceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryUntagResourceRequest(input, context)), { Action: "UntagResource", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_queryVerifySMSSandboxPhoneNumberCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-www-form-urlencoded",
        };
        body = buildFormUrlencodedString(__assign(__assign({}, serializeAws_queryVerifySMSSandboxPhoneNumberInput(input, context)), { Action: "VerifySMSSandboxPhoneNumber", Version: "2010-03-31" }));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var deserializeAws_queryAddPermissionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryAddPermissionCommandError(output, context)];
                }
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                response = {
                    $metadata: deserializeMetadata(output),
                };
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryAddPermissionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "NotFoundException": return [3, 8];
                    case "com.amazonaws.sns#NotFoundException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryCheckIfPhoneNumberIsOptedOutCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryCheckIfPhoneNumberIsOptedOutCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryCheckIfPhoneNumberIsOptedOutResponse(data.CheckIfPhoneNumberIsOptedOutResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryCheckIfPhoneNumberIsOptedOutCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "ThrottledException": return [3, 8];
                    case "com.amazonaws.sns#ThrottledException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryThrottledExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryConfirmSubscriptionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryConfirmSubscriptionCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryConfirmSubscriptionResponse(data.ConfirmSubscriptionResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryConfirmSubscriptionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "FilterPolicyLimitExceededException": return [3, 4];
                    case "com.amazonaws.sns#FilterPolicyLimitExceededException": return [3, 4];
                    case "InternalErrorException": return [3, 6];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 6];
                    case "InvalidParameterException": return [3, 8];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.sns#NotFoundException": return [3, 10];
                    case "SubscriptionLimitExceededException": return [3, 12];
                    case "com.amazonaws.sns#SubscriptionLimitExceededException": return [3, 12];
                }
                return [3, 14];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryFilterPolicyLimitExceededExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_querySubscriptionLimitExceededExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryCreatePlatformApplicationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryCreatePlatformApplicationCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryCreatePlatformApplicationResponse(data.CreatePlatformApplicationResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryCreatePlatformApplicationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                }
                return [3, 8];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryCreatePlatformEndpointCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryCreatePlatformEndpointCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryCreateEndpointResponse(data.CreatePlatformEndpointResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryCreatePlatformEndpointCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "NotFoundException": return [3, 8];
                    case "com.amazonaws.sns#NotFoundException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryCreateSMSSandboxPhoneNumberCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryCreateSMSSandboxPhoneNumberCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryCreateSMSSandboxPhoneNumberResult(data.CreateSMSSandboxPhoneNumberResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryCreateSMSSandboxPhoneNumberCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "OptedOutException": return [3, 8];
                    case "com.amazonaws.sns#OptedOutException": return [3, 8];
                    case "ThrottledException": return [3, 10];
                    case "com.amazonaws.sns#ThrottledException": return [3, 10];
                    case "UserErrorException": return [3, 12];
                    case "com.amazonaws.sns#UserErrorException": return [3, 12];
                }
                return [3, 14];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryOptedOutExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryThrottledExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_queryUserErrorExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryCreateTopicCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryCreateTopicCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryCreateTopicResponse(data.CreateTopicResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryCreateTopicCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "ConcurrentAccessException": return [3, 4];
                    case "com.amazonaws.sns#ConcurrentAccessException": return [3, 4];
                    case "InternalErrorException": return [3, 6];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 6];
                    case "InvalidParameterException": return [3, 8];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 8];
                    case "InvalidSecurityException": return [3, 10];
                    case "com.amazonaws.sns#InvalidSecurityException": return [3, 10];
                    case "StaleTagException": return [3, 12];
                    case "com.amazonaws.sns#StaleTagException": return [3, 12];
                    case "TagLimitExceededException": return [3, 14];
                    case "com.amazonaws.sns#TagLimitExceededException": return [3, 14];
                    case "TagPolicyException": return [3, 16];
                    case "com.amazonaws.sns#TagPolicyException": return [3, 16];
                    case "TopicLimitExceededException": return [3, 18];
                    case "com.amazonaws.sns#TopicLimitExceededException": return [3, 18];
                }
                return [3, 20];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryConcurrentAccessExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryInvalidSecurityExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_queryStaleTagExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_queryTagLimitExceededExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16: return [4, deserializeAws_queryTagPolicyExceptionResponse(parsedOutput, context)];
            case 17: throw _d.sent();
            case 18: return [4, deserializeAws_queryTopicLimitExceededExceptionResponse(parsedOutput, context)];
            case 19: throw _d.sent();
            case 20:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryDeleteEndpointCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryDeleteEndpointCommandError(output, context)];
                }
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                response = {
                    $metadata: deserializeMetadata(output),
                };
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryDeleteEndpointCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                }
                return [3, 8];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryDeletePlatformApplicationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryDeletePlatformApplicationCommandError(output, context)];
                }
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                response = {
                    $metadata: deserializeMetadata(output),
                };
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryDeletePlatformApplicationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                }
                return [3, 8];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryDeleteSMSSandboxPhoneNumberCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryDeleteSMSSandboxPhoneNumberCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryDeleteSMSSandboxPhoneNumberResult(data.DeleteSMSSandboxPhoneNumberResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryDeleteSMSSandboxPhoneNumberCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "ResourceNotFoundException": return [3, 8];
                    case "com.amazonaws.sns#ResourceNotFoundException": return [3, 8];
                    case "ThrottledException": return [3, 10];
                    case "com.amazonaws.sns#ThrottledException": return [3, 10];
                    case "UserErrorException": return [3, 12];
                    case "com.amazonaws.sns#UserErrorException": return [3, 12];
                }
                return [3, 14];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryThrottledExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_queryUserErrorExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryDeleteTopicCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryDeleteTopicCommandError(output, context)];
                }
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                response = {
                    $metadata: deserializeMetadata(output),
                };
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryDeleteTopicCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "ConcurrentAccessException": return [3, 4];
                    case "com.amazonaws.sns#ConcurrentAccessException": return [3, 4];
                    case "InternalErrorException": return [3, 6];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 6];
                    case "InvalidParameterException": return [3, 8];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.sns#NotFoundException": return [3, 10];
                    case "StaleTagException": return [3, 12];
                    case "com.amazonaws.sns#StaleTagException": return [3, 12];
                    case "TagPolicyException": return [3, 14];
                    case "com.amazonaws.sns#TagPolicyException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryConcurrentAccessExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_queryStaleTagExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_queryTagPolicyExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryGetEndpointAttributesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryGetEndpointAttributesCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryGetEndpointAttributesResponse(data.GetEndpointAttributesResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryGetEndpointAttributesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "NotFoundException": return [3, 8];
                    case "com.amazonaws.sns#NotFoundException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryGetPlatformApplicationAttributesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryGetPlatformApplicationAttributesCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryGetPlatformApplicationAttributesResponse(data.GetPlatformApplicationAttributesResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryGetPlatformApplicationAttributesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "NotFoundException": return [3, 8];
                    case "com.amazonaws.sns#NotFoundException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryGetSMSAttributesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryGetSMSAttributesCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryGetSMSAttributesResponse(data.GetSMSAttributesResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryGetSMSAttributesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "ThrottledException": return [3, 8];
                    case "com.amazonaws.sns#ThrottledException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryThrottledExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryGetSMSSandboxAccountStatusCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryGetSMSSandboxAccountStatusCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryGetSMSSandboxAccountStatusResult(data.GetSMSSandboxAccountStatusResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryGetSMSSandboxAccountStatusCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "ThrottledException": return [3, 6];
                    case "com.amazonaws.sns#ThrottledException": return [3, 6];
                }
                return [3, 8];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryThrottledExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryGetSubscriptionAttributesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryGetSubscriptionAttributesCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryGetSubscriptionAttributesResponse(data.GetSubscriptionAttributesResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryGetSubscriptionAttributesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "NotFoundException": return [3, 8];
                    case "com.amazonaws.sns#NotFoundException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryGetTopicAttributesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryGetTopicAttributesCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryGetTopicAttributesResponse(data.GetTopicAttributesResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryGetTopicAttributesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "InvalidSecurityException": return [3, 8];
                    case "com.amazonaws.sns#InvalidSecurityException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.sns#NotFoundException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryInvalidSecurityExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryListEndpointsByPlatformApplicationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryListEndpointsByPlatformApplicationCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryListEndpointsByPlatformApplicationResponse(data.ListEndpointsByPlatformApplicationResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryListEndpointsByPlatformApplicationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "NotFoundException": return [3, 8];
                    case "com.amazonaws.sns#NotFoundException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryListOriginationNumbersCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryListOriginationNumbersCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryListOriginationNumbersResult(data.ListOriginationNumbersResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryListOriginationNumbersCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "ThrottledException": return [3, 8];
                    case "com.amazonaws.sns#ThrottledException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.sns#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryThrottledExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryValidationExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryListPhoneNumbersOptedOutCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryListPhoneNumbersOptedOutCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryListPhoneNumbersOptedOutResponse(data.ListPhoneNumbersOptedOutResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryListPhoneNumbersOptedOutCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "ThrottledException": return [3, 8];
                    case "com.amazonaws.sns#ThrottledException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryThrottledExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryListPlatformApplicationsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryListPlatformApplicationsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryListPlatformApplicationsResponse(data.ListPlatformApplicationsResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryListPlatformApplicationsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                }
                return [3, 8];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryListSMSSandboxPhoneNumbersCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryListSMSSandboxPhoneNumbersCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryListSMSSandboxPhoneNumbersResult(data.ListSMSSandboxPhoneNumbersResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryListSMSSandboxPhoneNumbersCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "ResourceNotFoundException": return [3, 8];
                    case "com.amazonaws.sns#ResourceNotFoundException": return [3, 8];
                    case "ThrottledException": return [3, 10];
                    case "com.amazonaws.sns#ThrottledException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryThrottledExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryListSubscriptionsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryListSubscriptionsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryListSubscriptionsResponse(data.ListSubscriptionsResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryListSubscriptionsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                }
                return [3, 8];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryListSubscriptionsByTopicCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryListSubscriptionsByTopicCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryListSubscriptionsByTopicResponse(data.ListSubscriptionsByTopicResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryListSubscriptionsByTopicCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "NotFoundException": return [3, 8];
                    case "com.amazonaws.sns#NotFoundException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryListTagsForResourceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryListTagsForResourceCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryListTagsForResourceResponse(data.ListTagsForResourceResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryListTagsForResourceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "ConcurrentAccessException": return [3, 4];
                    case "com.amazonaws.sns#ConcurrentAccessException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "ResourceNotFoundException": return [3, 8];
                    case "com.amazonaws.sns#ResourceNotFoundException": return [3, 8];
                    case "TagPolicyException": return [3, 10];
                    case "com.amazonaws.sns#TagPolicyException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryConcurrentAccessExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryTagPolicyExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryListTopicsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryListTopicsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryListTopicsResponse(data.ListTopicsResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryListTopicsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                }
                return [3, 8];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryOptInPhoneNumberCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryOptInPhoneNumberCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryOptInPhoneNumberResponse(data.OptInPhoneNumberResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryOptInPhoneNumberCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "ThrottledException": return [3, 8];
                    case "com.amazonaws.sns#ThrottledException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryThrottledExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryPublishCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryPublishCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryPublishResponse(data.PublishResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryPublishCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "EndpointDisabledException": return [3, 4];
                    case "com.amazonaws.sns#EndpointDisabledException": return [3, 4];
                    case "InternalErrorException": return [3, 6];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 6];
                    case "InvalidParameterException": return [3, 8];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 8];
                    case "InvalidParameterValueException": return [3, 10];
                    case "com.amazonaws.sns#InvalidParameterValueException": return [3, 10];
                    case "InvalidSecurityException": return [3, 12];
                    case "com.amazonaws.sns#InvalidSecurityException": return [3, 12];
                    case "KMSAccessDeniedException": return [3, 14];
                    case "com.amazonaws.sns#KMSAccessDeniedException": return [3, 14];
                    case "KMSDisabledException": return [3, 16];
                    case "com.amazonaws.sns#KMSDisabledException": return [3, 16];
                    case "KMSInvalidStateException": return [3, 18];
                    case "com.amazonaws.sns#KMSInvalidStateException": return [3, 18];
                    case "KMSNotFoundException": return [3, 20];
                    case "com.amazonaws.sns#KMSNotFoundException": return [3, 20];
                    case "KMSOptInRequired": return [3, 22];
                    case "com.amazonaws.sns#KMSOptInRequired": return [3, 22];
                    case "KMSThrottlingException": return [3, 24];
                    case "com.amazonaws.sns#KMSThrottlingException": return [3, 24];
                    case "NotFoundException": return [3, 26];
                    case "com.amazonaws.sns#NotFoundException": return [3, 26];
                    case "PlatformApplicationDisabledException": return [3, 28];
                    case "com.amazonaws.sns#PlatformApplicationDisabledException": return [3, 28];
                }
                return [3, 30];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryEndpointDisabledExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryInvalidParameterValueExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_queryInvalidSecurityExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_queryKMSAccessDeniedExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16: return [4, deserializeAws_queryKMSDisabledExceptionResponse(parsedOutput, context)];
            case 17: throw _d.sent();
            case 18: return [4, deserializeAws_queryKMSInvalidStateExceptionResponse(parsedOutput, context)];
            case 19: throw _d.sent();
            case 20: return [4, deserializeAws_queryKMSNotFoundExceptionResponse(parsedOutput, context)];
            case 21: throw _d.sent();
            case 22: return [4, deserializeAws_queryKMSOptInRequiredResponse(parsedOutput, context)];
            case 23: throw _d.sent();
            case 24: return [4, deserializeAws_queryKMSThrottlingExceptionResponse(parsedOutput, context)];
            case 25: throw _d.sent();
            case 26: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 27: throw _d.sent();
            case 28: return [4, deserializeAws_queryPlatformApplicationDisabledExceptionResponse(parsedOutput, context)];
            case 29: throw _d.sent();
            case 30:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryPublishBatchCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryPublishBatchCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryPublishBatchResponse(data.PublishBatchResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryPublishBatchCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "BatchEntryIdsNotDistinctException": return [3, 4];
                    case "com.amazonaws.sns#BatchEntryIdsNotDistinctException": return [3, 4];
                    case "BatchRequestTooLongException": return [3, 6];
                    case "com.amazonaws.sns#BatchRequestTooLongException": return [3, 6];
                    case "EmptyBatchRequestException": return [3, 8];
                    case "com.amazonaws.sns#EmptyBatchRequestException": return [3, 8];
                    case "EndpointDisabledException": return [3, 10];
                    case "com.amazonaws.sns#EndpointDisabledException": return [3, 10];
                    case "InternalErrorException": return [3, 12];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 12];
                    case "InvalidBatchEntryIdException": return [3, 14];
                    case "com.amazonaws.sns#InvalidBatchEntryIdException": return [3, 14];
                    case "InvalidParameterException": return [3, 16];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 16];
                    case "InvalidParameterValueException": return [3, 18];
                    case "com.amazonaws.sns#InvalidParameterValueException": return [3, 18];
                    case "InvalidSecurityException": return [3, 20];
                    case "com.amazonaws.sns#InvalidSecurityException": return [3, 20];
                    case "KMSAccessDeniedException": return [3, 22];
                    case "com.amazonaws.sns#KMSAccessDeniedException": return [3, 22];
                    case "KMSDisabledException": return [3, 24];
                    case "com.amazonaws.sns#KMSDisabledException": return [3, 24];
                    case "KMSInvalidStateException": return [3, 26];
                    case "com.amazonaws.sns#KMSInvalidStateException": return [3, 26];
                    case "KMSNotFoundException": return [3, 28];
                    case "com.amazonaws.sns#KMSNotFoundException": return [3, 28];
                    case "KMSOptInRequired": return [3, 30];
                    case "com.amazonaws.sns#KMSOptInRequired": return [3, 30];
                    case "KMSThrottlingException": return [3, 32];
                    case "com.amazonaws.sns#KMSThrottlingException": return [3, 32];
                    case "NotFoundException": return [3, 34];
                    case "com.amazonaws.sns#NotFoundException": return [3, 34];
                    case "PlatformApplicationDisabledException": return [3, 36];
                    case "com.amazonaws.sns#PlatformApplicationDisabledException": return [3, 36];
                    case "TooManyEntriesInBatchRequestException": return [3, 38];
                    case "com.amazonaws.sns#TooManyEntriesInBatchRequestException": return [3, 38];
                }
                return [3, 40];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryBatchEntryIdsNotDistinctExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryBatchRequestTooLongExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryEmptyBatchRequestExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryEndpointDisabledExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_queryInvalidBatchEntryIdExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 17: throw _d.sent();
            case 18: return [4, deserializeAws_queryInvalidParameterValueExceptionResponse(parsedOutput, context)];
            case 19: throw _d.sent();
            case 20: return [4, deserializeAws_queryInvalidSecurityExceptionResponse(parsedOutput, context)];
            case 21: throw _d.sent();
            case 22: return [4, deserializeAws_queryKMSAccessDeniedExceptionResponse(parsedOutput, context)];
            case 23: throw _d.sent();
            case 24: return [4, deserializeAws_queryKMSDisabledExceptionResponse(parsedOutput, context)];
            case 25: throw _d.sent();
            case 26: return [4, deserializeAws_queryKMSInvalidStateExceptionResponse(parsedOutput, context)];
            case 27: throw _d.sent();
            case 28: return [4, deserializeAws_queryKMSNotFoundExceptionResponse(parsedOutput, context)];
            case 29: throw _d.sent();
            case 30: return [4, deserializeAws_queryKMSOptInRequiredResponse(parsedOutput, context)];
            case 31: throw _d.sent();
            case 32: return [4, deserializeAws_queryKMSThrottlingExceptionResponse(parsedOutput, context)];
            case 33: throw _d.sent();
            case 34: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 35: throw _d.sent();
            case 36: return [4, deserializeAws_queryPlatformApplicationDisabledExceptionResponse(parsedOutput, context)];
            case 37: throw _d.sent();
            case 38: return [4, deserializeAws_queryTooManyEntriesInBatchRequestExceptionResponse(parsedOutput, context)];
            case 39: throw _d.sent();
            case 40:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryRemovePermissionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryRemovePermissionCommandError(output, context)];
                }
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                response = {
                    $metadata: deserializeMetadata(output),
                };
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryRemovePermissionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "NotFoundException": return [3, 8];
                    case "com.amazonaws.sns#NotFoundException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_querySetEndpointAttributesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_querySetEndpointAttributesCommandError(output, context)];
                }
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                response = {
                    $metadata: deserializeMetadata(output),
                };
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_querySetEndpointAttributesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "NotFoundException": return [3, 8];
                    case "com.amazonaws.sns#NotFoundException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_querySetPlatformApplicationAttributesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_querySetPlatformApplicationAttributesCommandError(output, context)];
                }
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                response = {
                    $metadata: deserializeMetadata(output),
                };
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_querySetPlatformApplicationAttributesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "NotFoundException": return [3, 8];
                    case "com.amazonaws.sns#NotFoundException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_querySetSMSAttributesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_querySetSMSAttributesCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_querySetSMSAttributesResponse(data.SetSMSAttributesResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_querySetSMSAttributesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "ThrottledException": return [3, 8];
                    case "com.amazonaws.sns#ThrottledException": return [3, 8];
                }
                return [3, 10];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryThrottledExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_querySetSubscriptionAttributesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_querySetSubscriptionAttributesCommandError(output, context)];
                }
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                response = {
                    $metadata: deserializeMetadata(output),
                };
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_querySetSubscriptionAttributesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "FilterPolicyLimitExceededException": return [3, 4];
                    case "com.amazonaws.sns#FilterPolicyLimitExceededException": return [3, 4];
                    case "InternalErrorException": return [3, 6];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 6];
                    case "InvalidParameterException": return [3, 8];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.sns#NotFoundException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryFilterPolicyLimitExceededExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_querySetTopicAttributesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_querySetTopicAttributesCommandError(output, context)];
                }
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                response = {
                    $metadata: deserializeMetadata(output),
                };
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_querySetTopicAttributesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "InvalidSecurityException": return [3, 8];
                    case "com.amazonaws.sns#InvalidSecurityException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.sns#NotFoundException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryInvalidSecurityExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_querySubscribeCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_querySubscribeCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_querySubscribeResponse(data.SubscribeResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_querySubscribeCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "FilterPolicyLimitExceededException": return [3, 4];
                    case "com.amazonaws.sns#FilterPolicyLimitExceededException": return [3, 4];
                    case "InternalErrorException": return [3, 6];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 6];
                    case "InvalidParameterException": return [3, 8];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 8];
                    case "InvalidSecurityException": return [3, 10];
                    case "com.amazonaws.sns#InvalidSecurityException": return [3, 10];
                    case "NotFoundException": return [3, 12];
                    case "com.amazonaws.sns#NotFoundException": return [3, 12];
                    case "SubscriptionLimitExceededException": return [3, 14];
                    case "com.amazonaws.sns#SubscriptionLimitExceededException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryFilterPolicyLimitExceededExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryInvalidSecurityExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_querySubscriptionLimitExceededExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryTagResourceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryTagResourceCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryTagResourceResponse(data.TagResourceResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryTagResourceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "ConcurrentAccessException": return [3, 4];
                    case "com.amazonaws.sns#ConcurrentAccessException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "ResourceNotFoundException": return [3, 8];
                    case "com.amazonaws.sns#ResourceNotFoundException": return [3, 8];
                    case "StaleTagException": return [3, 10];
                    case "com.amazonaws.sns#StaleTagException": return [3, 10];
                    case "TagLimitExceededException": return [3, 12];
                    case "com.amazonaws.sns#TagLimitExceededException": return [3, 12];
                    case "TagPolicyException": return [3, 14];
                    case "com.amazonaws.sns#TagPolicyException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryConcurrentAccessExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryStaleTagExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_queryTagLimitExceededExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_queryTagPolicyExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryUnsubscribeCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryUnsubscribeCommandError(output, context)];
                }
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                response = {
                    $metadata: deserializeMetadata(output),
                };
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryUnsubscribeCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "InvalidSecurityException": return [3, 8];
                    case "com.amazonaws.sns#InvalidSecurityException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.sns#NotFoundException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryInvalidSecurityExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryNotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryUntagResourceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryUntagResourceCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryUntagResourceResponse(data.UntagResourceResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryUntagResourceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "ConcurrentAccessException": return [3, 4];
                    case "com.amazonaws.sns#ConcurrentAccessException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "ResourceNotFoundException": return [3, 8];
                    case "com.amazonaws.sns#ResourceNotFoundException": return [3, 8];
                    case "StaleTagException": return [3, 10];
                    case "com.amazonaws.sns#StaleTagException": return [3, 10];
                    case "TagLimitExceededException": return [3, 12];
                    case "com.amazonaws.sns#TagLimitExceededException": return [3, 12];
                    case "TagPolicyException": return [3, 14];
                    case "com.amazonaws.sns#TagPolicyException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryConcurrentAccessExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryStaleTagExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_queryTagLimitExceededExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_queryTagPolicyExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
export var deserializeAws_queryVerifySMSSandboxPhoneNumberCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_queryVerifySMSSandboxPhoneNumberCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_queryVerifySMSSandboxPhoneNumberResult(data.VerifySMSSandboxPhoneNumberResult, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_queryVerifySMSSandboxPhoneNumberCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = "UnknownError";
                errorCode = loadQueryErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AuthorizationErrorException": return [3, 2];
                    case "com.amazonaws.sns#AuthorizationErrorException": return [3, 2];
                    case "InternalErrorException": return [3, 4];
                    case "com.amazonaws.sns#InternalErrorException": return [3, 4];
                    case "InvalidParameterException": return [3, 6];
                    case "com.amazonaws.sns#InvalidParameterException": return [3, 6];
                    case "ResourceNotFoundException": return [3, 8];
                    case "com.amazonaws.sns#ResourceNotFoundException": return [3, 8];
                    case "ThrottledException": return [3, 10];
                    case "com.amazonaws.sns#ThrottledException": return [3, 10];
                    case "VerificationException": return [3, 12];
                    case "com.amazonaws.sns#VerificationException": return [3, 12];
                }
                return [3, 14];
            case 2: return [4, deserializeAws_queryAuthorizationErrorExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_queryInternalErrorExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_queryInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_queryResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_queryThrottledExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_queryVerificationExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14:
                parsedBody = parsedOutput.body;
                response = new __BaseException({
                    name: parsedBody.Error.code || parsedBody.Error.Code || errorCode,
                    $fault: "client",
                    $metadata: deserializeMetadata(output),
                });
                throw __decorateServiceException(response, parsedBody.Error);
        }
    });
}); };
var deserializeAws_queryAuthorizationErrorExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryAuthorizationErrorException(body.Error, context);
        exception = new AuthorizationErrorException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryBatchEntryIdsNotDistinctExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryBatchEntryIdsNotDistinctException(body.Error, context);
        exception = new BatchEntryIdsNotDistinctException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryBatchRequestTooLongExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryBatchRequestTooLongException(body.Error, context);
        exception = new BatchRequestTooLongException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryConcurrentAccessExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryConcurrentAccessException(body.Error, context);
        exception = new ConcurrentAccessException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryEmptyBatchRequestExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryEmptyBatchRequestException(body.Error, context);
        exception = new EmptyBatchRequestException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryEndpointDisabledExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryEndpointDisabledException(body.Error, context);
        exception = new EndpointDisabledException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryFilterPolicyLimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryFilterPolicyLimitExceededException(body.Error, context);
        exception = new FilterPolicyLimitExceededException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryInternalErrorExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryInternalErrorException(body.Error, context);
        exception = new InternalErrorException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryInvalidBatchEntryIdExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryInvalidBatchEntryIdException(body.Error, context);
        exception = new InvalidBatchEntryIdException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryInvalidParameterExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryInvalidParameterException(body.Error, context);
        exception = new InvalidParameterException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryInvalidParameterValueExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryInvalidParameterValueException(body.Error, context);
        exception = new InvalidParameterValueException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryInvalidSecurityExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryInvalidSecurityException(body.Error, context);
        exception = new InvalidSecurityException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryKMSAccessDeniedExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryKMSAccessDeniedException(body.Error, context);
        exception = new KMSAccessDeniedException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryKMSDisabledExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryKMSDisabledException(body.Error, context);
        exception = new KMSDisabledException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryKMSInvalidStateExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryKMSInvalidStateException(body.Error, context);
        exception = new KMSInvalidStateException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryKMSNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryKMSNotFoundException(body.Error, context);
        exception = new KMSNotFoundException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryKMSOptInRequiredResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryKMSOptInRequired(body.Error, context);
        exception = new KMSOptInRequired(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryKMSThrottlingExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryKMSThrottlingException(body.Error, context);
        exception = new KMSThrottlingException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryNotFoundException(body.Error, context);
        exception = new NotFoundException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryOptedOutExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryOptedOutException(body.Error, context);
        exception = new OptedOutException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryPlatformApplicationDisabledExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryPlatformApplicationDisabledException(body.Error, context);
        exception = new PlatformApplicationDisabledException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryResourceNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryResourceNotFoundException(body.Error, context);
        exception = new ResourceNotFoundException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryStaleTagExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryStaleTagException(body.Error, context);
        exception = new StaleTagException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_querySubscriptionLimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_querySubscriptionLimitExceededException(body.Error, context);
        exception = new SubscriptionLimitExceededException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryTagLimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryTagLimitExceededException(body.Error, context);
        exception = new TagLimitExceededException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryTagPolicyExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryTagPolicyException(body.Error, context);
        exception = new TagPolicyException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryThrottledExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryThrottledException(body.Error, context);
        exception = new ThrottledException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryTooManyEntriesInBatchRequestExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryTooManyEntriesInBatchRequestException(body.Error, context);
        exception = new TooManyEntriesInBatchRequestException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryTopicLimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryTopicLimitExceededException(body.Error, context);
        exception = new TopicLimitExceededException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryUserErrorExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryUserErrorException(body.Error, context);
        exception = new UserErrorException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryValidationExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryValidationException(body.Error, context);
        exception = new ValidationException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var deserializeAws_queryVerificationExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, exception;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_queryVerificationException(body.Error, context);
        exception = new VerificationException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, deserialized));
        return [2, __decorateServiceException(exception, body)];
    });
}); };
var serializeAws_queryActionsList = function (input, context) {
    var e_1, _a;
    var entries = {};
    var counter = 1;
    try {
        for (var input_1 = __values(input), input_1_1 = input_1.next(); !input_1_1.done; input_1_1 = input_1.next()) {
            var entry = input_1_1.value;
            if (entry === null) {
                continue;
            }
            entries["member." + counter] = entry;
            counter++;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (input_1_1 && !input_1_1.done && (_a = input_1.return)) _a.call(input_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return entries;
};
var serializeAws_queryAddPermissionInput = function (input, context) {
    var entries = {};
    if (input.TopicArn !== undefined && input.TopicArn !== null) {
        entries["TopicArn"] = input.TopicArn;
    }
    if (input.Label !== undefined && input.Label !== null) {
        entries["Label"] = input.Label;
    }
    if (input.AWSAccountId !== undefined && input.AWSAccountId !== null) {
        var memberEntries = serializeAws_queryDelegatesList(input.AWSAccountId, context);
        Object.entries(memberEntries).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var loc = "AWSAccountId." + key;
            entries[loc] = value;
        });
    }
    if (input.ActionName !== undefined && input.ActionName !== null) {
        var memberEntries = serializeAws_queryActionsList(input.ActionName, context);
        Object.entries(memberEntries).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var loc = "ActionName." + key;
            entries[loc] = value;
        });
    }
    return entries;
};
var serializeAws_queryCheckIfPhoneNumberIsOptedOutInput = function (input, context) {
    var entries = {};
    if (input.phoneNumber !== undefined && input.phoneNumber !== null) {
        entries["phoneNumber"] = input.phoneNumber;
    }
    return entries;
};
var serializeAws_queryConfirmSubscriptionInput = function (input, context) {
    var entries = {};
    if (input.TopicArn !== undefined && input.TopicArn !== null) {
        entries["TopicArn"] = input.TopicArn;
    }
    if (input.Token !== undefined && input.Token !== null) {
        entries["Token"] = input.Token;
    }
    if (input.AuthenticateOnUnsubscribe !== undefined && input.AuthenticateOnUnsubscribe !== null) {
        entries["AuthenticateOnUnsubscribe"] = input.AuthenticateOnUnsubscribe;
    }
    return entries;
};
var serializeAws_queryCreatePlatformApplicationInput = function (input, context) {
    var entries = {};
    if (input.Name !== undefined && input.Name !== null) {
        entries["Name"] = input.Name;
    }
    if (input.Platform !== undefined && input.Platform !== null) {
        entries["Platform"] = input.Platform;
    }
    if (input.Attributes !== undefined && input.Attributes !== null) {
        var memberEntries = serializeAws_queryMapStringToString(input.Attributes, context);
        Object.entries(memberEntries).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var loc = "Attributes." + key;
            entries[loc] = value;
        });
    }
    return entries;
};
var serializeAws_queryCreatePlatformEndpointInput = function (input, context) {
    var entries = {};
    if (input.PlatformApplicationArn !== undefined && input.PlatformApplicationArn !== null) {
        entries["PlatformApplicationArn"] = input.PlatformApplicationArn;
    }
    if (input.Token !== undefined && input.Token !== null) {
        entries["Token"] = input.Token;
    }
    if (input.CustomUserData !== undefined && input.CustomUserData !== null) {
        entries["CustomUserData"] = input.CustomUserData;
    }
    if (input.Attributes !== undefined && input.Attributes !== null) {
        var memberEntries = serializeAws_queryMapStringToString(input.Attributes, context);
        Object.entries(memberEntries).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var loc = "Attributes." + key;
            entries[loc] = value;
        });
    }
    return entries;
};
var serializeAws_queryCreateSMSSandboxPhoneNumberInput = function (input, context) {
    var entries = {};
    if (input.PhoneNumber !== undefined && input.PhoneNumber !== null) {
        entries["PhoneNumber"] = input.PhoneNumber;
    }
    if (input.LanguageCode !== undefined && input.LanguageCode !== null) {
        entries["LanguageCode"] = input.LanguageCode;
    }
    return entries;
};
var serializeAws_queryCreateTopicInput = function (input, context) {
    var entries = {};
    if (input.Name !== undefined && input.Name !== null) {
        entries["Name"] = input.Name;
    }
    if (input.Attributes !== undefined && input.Attributes !== null) {
        var memberEntries = serializeAws_queryTopicAttributesMap(input.Attributes, context);
        Object.entries(memberEntries).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var loc = "Attributes." + key;
            entries[loc] = value;
        });
    }
    if (input.Tags !== undefined && input.Tags !== null) {
        var memberEntries = serializeAws_queryTagList(input.Tags, context);
        Object.entries(memberEntries).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var loc = "Tags." + key;
            entries[loc] = value;
        });
    }
    return entries;
};
var serializeAws_queryDelegatesList = function (input, context) {
    var e_2, _a;
    var entries = {};
    var counter = 1;
    try {
        for (var input_2 = __values(input), input_2_1 = input_2.next(); !input_2_1.done; input_2_1 = input_2.next()) {
            var entry = input_2_1.value;
            if (entry === null) {
                continue;
            }
            entries["member." + counter] = entry;
            counter++;
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (input_2_1 && !input_2_1.done && (_a = input_2.return)) _a.call(input_2);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return entries;
};
var serializeAws_queryDeleteEndpointInput = function (input, context) {
    var entries = {};
    if (input.EndpointArn !== undefined && input.EndpointArn !== null) {
        entries["EndpointArn"] = input.EndpointArn;
    }
    return entries;
};
var serializeAws_queryDeletePlatformApplicationInput = function (input, context) {
    var entries = {};
    if (input.PlatformApplicationArn !== undefined && input.PlatformApplicationArn !== null) {
        entries["PlatformApplicationArn"] = input.PlatformApplicationArn;
    }
    return entries;
};
var serializeAws_queryDeleteSMSSandboxPhoneNumberInput = function (input, context) {
    var entries = {};
    if (input.PhoneNumber !== undefined && input.PhoneNumber !== null) {
        entries["PhoneNumber"] = input.PhoneNumber;
    }
    return entries;
};
var serializeAws_queryDeleteTopicInput = function (input, context) {
    var entries = {};
    if (input.TopicArn !== undefined && input.TopicArn !== null) {
        entries["TopicArn"] = input.TopicArn;
    }
    return entries;
};
var serializeAws_queryGetEndpointAttributesInput = function (input, context) {
    var entries = {};
    if (input.EndpointArn !== undefined && input.EndpointArn !== null) {
        entries["EndpointArn"] = input.EndpointArn;
    }
    return entries;
};
var serializeAws_queryGetPlatformApplicationAttributesInput = function (input, context) {
    var entries = {};
    if (input.PlatformApplicationArn !== undefined && input.PlatformApplicationArn !== null) {
        entries["PlatformApplicationArn"] = input.PlatformApplicationArn;
    }
    return entries;
};
var serializeAws_queryGetSMSAttributesInput = function (input, context) {
    var entries = {};
    if (input.attributes !== undefined && input.attributes !== null) {
        var memberEntries = serializeAws_queryListString(input.attributes, context);
        Object.entries(memberEntries).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var loc = "attributes." + key;
            entries[loc] = value;
        });
    }
    return entries;
};
var serializeAws_queryGetSMSSandboxAccountStatusInput = function (input, context) {
    var entries = {};
    return entries;
};
var serializeAws_queryGetSubscriptionAttributesInput = function (input, context) {
    var entries = {};
    if (input.SubscriptionArn !== undefined && input.SubscriptionArn !== null) {
        entries["SubscriptionArn"] = input.SubscriptionArn;
    }
    return entries;
};
var serializeAws_queryGetTopicAttributesInput = function (input, context) {
    var entries = {};
    if (input.TopicArn !== undefined && input.TopicArn !== null) {
        entries["TopicArn"] = input.TopicArn;
    }
    return entries;
};
var serializeAws_queryListEndpointsByPlatformApplicationInput = function (input, context) {
    var entries = {};
    if (input.PlatformApplicationArn !== undefined && input.PlatformApplicationArn !== null) {
        entries["PlatformApplicationArn"] = input.PlatformApplicationArn;
    }
    if (input.NextToken !== undefined && input.NextToken !== null) {
        entries["NextToken"] = input.NextToken;
    }
    return entries;
};
var serializeAws_queryListOriginationNumbersRequest = function (input, context) {
    var entries = {};
    if (input.NextToken !== undefined && input.NextToken !== null) {
        entries["NextToken"] = input.NextToken;
    }
    if (input.MaxResults !== undefined && input.MaxResults !== null) {
        entries["MaxResults"] = input.MaxResults;
    }
    return entries;
};
var serializeAws_queryListPhoneNumbersOptedOutInput = function (input, context) {
    var entries = {};
    if (input.nextToken !== undefined && input.nextToken !== null) {
        entries["nextToken"] = input.nextToken;
    }
    return entries;
};
var serializeAws_queryListPlatformApplicationsInput = function (input, context) {
    var entries = {};
    if (input.NextToken !== undefined && input.NextToken !== null) {
        entries["NextToken"] = input.NextToken;
    }
    return entries;
};
var serializeAws_queryListSMSSandboxPhoneNumbersInput = function (input, context) {
    var entries = {};
    if (input.NextToken !== undefined && input.NextToken !== null) {
        entries["NextToken"] = input.NextToken;
    }
    if (input.MaxResults !== undefined && input.MaxResults !== null) {
        entries["MaxResults"] = input.MaxResults;
    }
    return entries;
};
var serializeAws_queryListString = function (input, context) {
    var e_3, _a;
    var entries = {};
    var counter = 1;
    try {
        for (var input_3 = __values(input), input_3_1 = input_3.next(); !input_3_1.done; input_3_1 = input_3.next()) {
            var entry = input_3_1.value;
            if (entry === null) {
                continue;
            }
            entries["member." + counter] = entry;
            counter++;
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (input_3_1 && !input_3_1.done && (_a = input_3.return)) _a.call(input_3);
        }
        finally { if (e_3) throw e_3.error; }
    }
    return entries;
};
var serializeAws_queryListSubscriptionsByTopicInput = function (input, context) {
    var entries = {};
    if (input.TopicArn !== undefined && input.TopicArn !== null) {
        entries["TopicArn"] = input.TopicArn;
    }
    if (input.NextToken !== undefined && input.NextToken !== null) {
        entries["NextToken"] = input.NextToken;
    }
    return entries;
};
var serializeAws_queryListSubscriptionsInput = function (input, context) {
    var entries = {};
    if (input.NextToken !== undefined && input.NextToken !== null) {
        entries["NextToken"] = input.NextToken;
    }
    return entries;
};
var serializeAws_queryListTagsForResourceRequest = function (input, context) {
    var entries = {};
    if (input.ResourceArn !== undefined && input.ResourceArn !== null) {
        entries["ResourceArn"] = input.ResourceArn;
    }
    return entries;
};
var serializeAws_queryListTopicsInput = function (input, context) {
    var entries = {};
    if (input.NextToken !== undefined && input.NextToken !== null) {
        entries["NextToken"] = input.NextToken;
    }
    return entries;
};
var serializeAws_queryMapStringToString = function (input, context) {
    var entries = {};
    var counter = 1;
    Object.keys(input)
        .filter(function (key) { return input[key] != null; })
        .forEach(function (key) {
        entries["entry." + counter + ".key"] = key;
        entries["entry." + counter + ".value"] = input[key];
        counter++;
    });
    return entries;
};
var serializeAws_queryMessageAttributeMap = function (input, context) {
    var entries = {};
    var counter = 1;
    Object.keys(input)
        .filter(function (key) { return input[key] != null; })
        .forEach(function (key) {
        entries["entry." + counter + ".Name"] = key;
        var memberEntries = serializeAws_queryMessageAttributeValue(input[key], context);
        Object.entries(memberEntries).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            entries["entry." + counter + ".Value." + key] = value;
        });
        counter++;
    });
    return entries;
};
var serializeAws_queryMessageAttributeValue = function (input, context) {
    var entries = {};
    if (input.DataType !== undefined && input.DataType !== null) {
        entries["DataType"] = input.DataType;
    }
    if (input.StringValue !== undefined && input.StringValue !== null) {
        entries["StringValue"] = input.StringValue;
    }
    if (input.BinaryValue !== undefined && input.BinaryValue !== null) {
        entries["BinaryValue"] = context.base64Encoder(input.BinaryValue);
    }
    return entries;
};
var serializeAws_queryOptInPhoneNumberInput = function (input, context) {
    var entries = {};
    if (input.phoneNumber !== undefined && input.phoneNumber !== null) {
        entries["phoneNumber"] = input.phoneNumber;
    }
    return entries;
};
var serializeAws_queryPublishBatchInput = function (input, context) {
    var entries = {};
    if (input.TopicArn !== undefined && input.TopicArn !== null) {
        entries["TopicArn"] = input.TopicArn;
    }
    if (input.PublishBatchRequestEntries !== undefined && input.PublishBatchRequestEntries !== null) {
        var memberEntries = serializeAws_queryPublishBatchRequestEntryList(input.PublishBatchRequestEntries, context);
        Object.entries(memberEntries).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var loc = "PublishBatchRequestEntries." + key;
            entries[loc] = value;
        });
    }
    return entries;
};
var serializeAws_queryPublishBatchRequestEntry = function (input, context) {
    var entries = {};
    if (input.Id !== undefined && input.Id !== null) {
        entries["Id"] = input.Id;
    }
    if (input.Message !== undefined && input.Message !== null) {
        entries["Message"] = input.Message;
    }
    if (input.Subject !== undefined && input.Subject !== null) {
        entries["Subject"] = input.Subject;
    }
    if (input.MessageStructure !== undefined && input.MessageStructure !== null) {
        entries["MessageStructure"] = input.MessageStructure;
    }
    if (input.MessageAttributes !== undefined && input.MessageAttributes !== null) {
        var memberEntries = serializeAws_queryMessageAttributeMap(input.MessageAttributes, context);
        Object.entries(memberEntries).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var loc = "MessageAttributes." + key;
            entries[loc] = value;
        });
    }
    if (input.MessageDeduplicationId !== undefined && input.MessageDeduplicationId !== null) {
        entries["MessageDeduplicationId"] = input.MessageDeduplicationId;
    }
    if (input.MessageGroupId !== undefined && input.MessageGroupId !== null) {
        entries["MessageGroupId"] = input.MessageGroupId;
    }
    return entries;
};
var serializeAws_queryPublishBatchRequestEntryList = function (input, context) {
    var e_4, _a;
    var entries = {};
    var counter = 1;
    try {
        for (var input_4 = __values(input), input_4_1 = input_4.next(); !input_4_1.done; input_4_1 = input_4.next()) {
            var entry = input_4_1.value;
            if (entry === null) {
                continue;
            }
            var memberEntries = serializeAws_queryPublishBatchRequestEntry(entry, context);
            Object.entries(memberEntries).forEach(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                entries["member." + counter + "." + key] = value;
            });
            counter++;
        }
    }
    catch (e_4_1) { e_4 = { error: e_4_1 }; }
    finally {
        try {
            if (input_4_1 && !input_4_1.done && (_a = input_4.return)) _a.call(input_4);
        }
        finally { if (e_4) throw e_4.error; }
    }
    return entries;
};
var serializeAws_queryPublishInput = function (input, context) {
    var entries = {};
    if (input.TopicArn !== undefined && input.TopicArn !== null) {
        entries["TopicArn"] = input.TopicArn;
    }
    if (input.TargetArn !== undefined && input.TargetArn !== null) {
        entries["TargetArn"] = input.TargetArn;
    }
    if (input.PhoneNumber !== undefined && input.PhoneNumber !== null) {
        entries["PhoneNumber"] = input.PhoneNumber;
    }
    if (input.Message !== undefined && input.Message !== null) {
        entries["Message"] = input.Message;
    }
    if (input.Subject !== undefined && input.Subject !== null) {
        entries["Subject"] = input.Subject;
    }
    if (input.MessageStructure !== undefined && input.MessageStructure !== null) {
        entries["MessageStructure"] = input.MessageStructure;
    }
    if (input.MessageAttributes !== undefined && input.MessageAttributes !== null) {
        var memberEntries = serializeAws_queryMessageAttributeMap(input.MessageAttributes, context);
        Object.entries(memberEntries).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var loc = "MessageAttributes." + key;
            entries[loc] = value;
        });
    }
    if (input.MessageDeduplicationId !== undefined && input.MessageDeduplicationId !== null) {
        entries["MessageDeduplicationId"] = input.MessageDeduplicationId;
    }
    if (input.MessageGroupId !== undefined && input.MessageGroupId !== null) {
        entries["MessageGroupId"] = input.MessageGroupId;
    }
    return entries;
};
var serializeAws_queryRemovePermissionInput = function (input, context) {
    var entries = {};
    if (input.TopicArn !== undefined && input.TopicArn !== null) {
        entries["TopicArn"] = input.TopicArn;
    }
    if (input.Label !== undefined && input.Label !== null) {
        entries["Label"] = input.Label;
    }
    return entries;
};
var serializeAws_querySetEndpointAttributesInput = function (input, context) {
    var entries = {};
    if (input.EndpointArn !== undefined && input.EndpointArn !== null) {
        entries["EndpointArn"] = input.EndpointArn;
    }
    if (input.Attributes !== undefined && input.Attributes !== null) {
        var memberEntries = serializeAws_queryMapStringToString(input.Attributes, context);
        Object.entries(memberEntries).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var loc = "Attributes." + key;
            entries[loc] = value;
        });
    }
    return entries;
};
var serializeAws_querySetPlatformApplicationAttributesInput = function (input, context) {
    var entries = {};
    if (input.PlatformApplicationArn !== undefined && input.PlatformApplicationArn !== null) {
        entries["PlatformApplicationArn"] = input.PlatformApplicationArn;
    }
    if (input.Attributes !== undefined && input.Attributes !== null) {
        var memberEntries = serializeAws_queryMapStringToString(input.Attributes, context);
        Object.entries(memberEntries).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var loc = "Attributes." + key;
            entries[loc] = value;
        });
    }
    return entries;
};
var serializeAws_querySetSMSAttributesInput = function (input, context) {
    var entries = {};
    if (input.attributes !== undefined && input.attributes !== null) {
        var memberEntries = serializeAws_queryMapStringToString(input.attributes, context);
        Object.entries(memberEntries).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var loc = "attributes." + key;
            entries[loc] = value;
        });
    }
    return entries;
};
var serializeAws_querySetSubscriptionAttributesInput = function (input, context) {
    var entries = {};
    if (input.SubscriptionArn !== undefined && input.SubscriptionArn !== null) {
        entries["SubscriptionArn"] = input.SubscriptionArn;
    }
    if (input.AttributeName !== undefined && input.AttributeName !== null) {
        entries["AttributeName"] = input.AttributeName;
    }
    if (input.AttributeValue !== undefined && input.AttributeValue !== null) {
        entries["AttributeValue"] = input.AttributeValue;
    }
    return entries;
};
var serializeAws_querySetTopicAttributesInput = function (input, context) {
    var entries = {};
    if (input.TopicArn !== undefined && input.TopicArn !== null) {
        entries["TopicArn"] = input.TopicArn;
    }
    if (input.AttributeName !== undefined && input.AttributeName !== null) {
        entries["AttributeName"] = input.AttributeName;
    }
    if (input.AttributeValue !== undefined && input.AttributeValue !== null) {
        entries["AttributeValue"] = input.AttributeValue;
    }
    return entries;
};
var serializeAws_querySubscribeInput = function (input, context) {
    var entries = {};
    if (input.TopicArn !== undefined && input.TopicArn !== null) {
        entries["TopicArn"] = input.TopicArn;
    }
    if (input.Protocol !== undefined && input.Protocol !== null) {
        entries["Protocol"] = input.Protocol;
    }
    if (input.Endpoint !== undefined && input.Endpoint !== null) {
        entries["Endpoint"] = input.Endpoint;
    }
    if (input.Attributes !== undefined && input.Attributes !== null) {
        var memberEntries = serializeAws_querySubscriptionAttributesMap(input.Attributes, context);
        Object.entries(memberEntries).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var loc = "Attributes." + key;
            entries[loc] = value;
        });
    }
    if (input.ReturnSubscriptionArn !== undefined && input.ReturnSubscriptionArn !== null) {
        entries["ReturnSubscriptionArn"] = input.ReturnSubscriptionArn;
    }
    return entries;
};
var serializeAws_querySubscriptionAttributesMap = function (input, context) {
    var entries = {};
    var counter = 1;
    Object.keys(input)
        .filter(function (key) { return input[key] != null; })
        .forEach(function (key) {
        entries["entry." + counter + ".key"] = key;
        entries["entry." + counter + ".value"] = input[key];
        counter++;
    });
    return entries;
};
var serializeAws_queryTag = function (input, context) {
    var entries = {};
    if (input.Key !== undefined && input.Key !== null) {
        entries["Key"] = input.Key;
    }
    if (input.Value !== undefined && input.Value !== null) {
        entries["Value"] = input.Value;
    }
    return entries;
};
var serializeAws_queryTagKeyList = function (input, context) {
    var e_5, _a;
    var entries = {};
    var counter = 1;
    try {
        for (var input_5 = __values(input), input_5_1 = input_5.next(); !input_5_1.done; input_5_1 = input_5.next()) {
            var entry = input_5_1.value;
            if (entry === null) {
                continue;
            }
            entries["member." + counter] = entry;
            counter++;
        }
    }
    catch (e_5_1) { e_5 = { error: e_5_1 }; }
    finally {
        try {
            if (input_5_1 && !input_5_1.done && (_a = input_5.return)) _a.call(input_5);
        }
        finally { if (e_5) throw e_5.error; }
    }
    return entries;
};
var serializeAws_queryTagList = function (input, context) {
    var e_6, _a;
    var entries = {};
    var counter = 1;
    try {
        for (var input_6 = __values(input), input_6_1 = input_6.next(); !input_6_1.done; input_6_1 = input_6.next()) {
            var entry = input_6_1.value;
            if (entry === null) {
                continue;
            }
            var memberEntries = serializeAws_queryTag(entry, context);
            Object.entries(memberEntries).forEach(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                entries["member." + counter + "." + key] = value;
            });
            counter++;
        }
    }
    catch (e_6_1) { e_6 = { error: e_6_1 }; }
    finally {
        try {
            if (input_6_1 && !input_6_1.done && (_a = input_6.return)) _a.call(input_6);
        }
        finally { if (e_6) throw e_6.error; }
    }
    return entries;
};
var serializeAws_queryTagResourceRequest = function (input, context) {
    var entries = {};
    if (input.ResourceArn !== undefined && input.ResourceArn !== null) {
        entries["ResourceArn"] = input.ResourceArn;
    }
    if (input.Tags !== undefined && input.Tags !== null) {
        var memberEntries = serializeAws_queryTagList(input.Tags, context);
        Object.entries(memberEntries).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var loc = "Tags." + key;
            entries[loc] = value;
        });
    }
    return entries;
};
var serializeAws_queryTopicAttributesMap = function (input, context) {
    var entries = {};
    var counter = 1;
    Object.keys(input)
        .filter(function (key) { return input[key] != null; })
        .forEach(function (key) {
        entries["entry." + counter + ".key"] = key;
        entries["entry." + counter + ".value"] = input[key];
        counter++;
    });
    return entries;
};
var serializeAws_queryUnsubscribeInput = function (input, context) {
    var entries = {};
    if (input.SubscriptionArn !== undefined && input.SubscriptionArn !== null) {
        entries["SubscriptionArn"] = input.SubscriptionArn;
    }
    return entries;
};
var serializeAws_queryUntagResourceRequest = function (input, context) {
    var entries = {};
    if (input.ResourceArn !== undefined && input.ResourceArn !== null) {
        entries["ResourceArn"] = input.ResourceArn;
    }
    if (input.TagKeys !== undefined && input.TagKeys !== null) {
        var memberEntries = serializeAws_queryTagKeyList(input.TagKeys, context);
        Object.entries(memberEntries).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var loc = "TagKeys." + key;
            entries[loc] = value;
        });
    }
    return entries;
};
var serializeAws_queryVerifySMSSandboxPhoneNumberInput = function (input, context) {
    var entries = {};
    if (input.PhoneNumber !== undefined && input.PhoneNumber !== null) {
        entries["PhoneNumber"] = input.PhoneNumber;
    }
    if (input.OneTimePassword !== undefined && input.OneTimePassword !== null) {
        entries["OneTimePassword"] = input.OneTimePassword;
    }
    return entries;
};
var deserializeAws_queryAuthorizationErrorException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryBatchEntryIdsNotDistinctException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryBatchRequestTooLongException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryBatchResultErrorEntry = function (output, context) {
    var contents = {
        Id: undefined,
        Code: undefined,
        Message: undefined,
        SenderFault: undefined,
    };
    if (output["Id"] !== undefined) {
        contents.Id = __expectString(output["Id"]);
    }
    if (output["Code"] !== undefined) {
        contents.Code = __expectString(output["Code"]);
    }
    if (output["Message"] !== undefined) {
        contents.Message = __expectString(output["Message"]);
    }
    if (output["SenderFault"] !== undefined) {
        contents.SenderFault = __parseBoolean(output["SenderFault"]);
    }
    return contents;
};
var deserializeAws_queryBatchResultErrorEntryList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_queryBatchResultErrorEntry(entry, context);
    });
};
var deserializeAws_queryCheckIfPhoneNumberIsOptedOutResponse = function (output, context) {
    var contents = {
        isOptedOut: undefined,
    };
    if (output["isOptedOut"] !== undefined) {
        contents.isOptedOut = __parseBoolean(output["isOptedOut"]);
    }
    return contents;
};
var deserializeAws_queryConcurrentAccessException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryConfirmSubscriptionResponse = function (output, context) {
    var contents = {
        SubscriptionArn: undefined,
    };
    if (output["SubscriptionArn"] !== undefined) {
        contents.SubscriptionArn = __expectString(output["SubscriptionArn"]);
    }
    return contents;
};
var deserializeAws_queryCreateEndpointResponse = function (output, context) {
    var contents = {
        EndpointArn: undefined,
    };
    if (output["EndpointArn"] !== undefined) {
        contents.EndpointArn = __expectString(output["EndpointArn"]);
    }
    return contents;
};
var deserializeAws_queryCreatePlatformApplicationResponse = function (output, context) {
    var contents = {
        PlatformApplicationArn: undefined,
    };
    if (output["PlatformApplicationArn"] !== undefined) {
        contents.PlatformApplicationArn = __expectString(output["PlatformApplicationArn"]);
    }
    return contents;
};
var deserializeAws_queryCreateSMSSandboxPhoneNumberResult = function (output, context) {
    var contents = {};
    return contents;
};
var deserializeAws_queryCreateTopicResponse = function (output, context) {
    var contents = {
        TopicArn: undefined,
    };
    if (output["TopicArn"] !== undefined) {
        contents.TopicArn = __expectString(output["TopicArn"]);
    }
    return contents;
};
var deserializeAws_queryDeleteSMSSandboxPhoneNumberResult = function (output, context) {
    var contents = {};
    return contents;
};
var deserializeAws_queryEmptyBatchRequestException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryEndpoint = function (output, context) {
    var contents = {
        EndpointArn: undefined,
        Attributes: undefined,
    };
    if (output["EndpointArn"] !== undefined) {
        contents.EndpointArn = __expectString(output["EndpointArn"]);
    }
    if (output.Attributes === "") {
        contents.Attributes = {};
    }
    if (output["Attributes"] !== undefined && output["Attributes"]["entry"] !== undefined) {
        contents.Attributes = deserializeAws_queryMapStringToString(__getArrayIfSingleItem(output["Attributes"]["entry"]), context);
    }
    return contents;
};
var deserializeAws_queryEndpointDisabledException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryFilterPolicyLimitExceededException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryGetEndpointAttributesResponse = function (output, context) {
    var contents = {
        Attributes: undefined,
    };
    if (output.Attributes === "") {
        contents.Attributes = {};
    }
    if (output["Attributes"] !== undefined && output["Attributes"]["entry"] !== undefined) {
        contents.Attributes = deserializeAws_queryMapStringToString(__getArrayIfSingleItem(output["Attributes"]["entry"]), context);
    }
    return contents;
};
var deserializeAws_queryGetPlatformApplicationAttributesResponse = function (output, context) {
    var contents = {
        Attributes: undefined,
    };
    if (output.Attributes === "") {
        contents.Attributes = {};
    }
    if (output["Attributes"] !== undefined && output["Attributes"]["entry"] !== undefined) {
        contents.Attributes = deserializeAws_queryMapStringToString(__getArrayIfSingleItem(output["Attributes"]["entry"]), context);
    }
    return contents;
};
var deserializeAws_queryGetSMSAttributesResponse = function (output, context) {
    var contents = {
        attributes: undefined,
    };
    if (output.attributes === "") {
        contents.attributes = {};
    }
    if (output["attributes"] !== undefined && output["attributes"]["entry"] !== undefined) {
        contents.attributes = deserializeAws_queryMapStringToString(__getArrayIfSingleItem(output["attributes"]["entry"]), context);
    }
    return contents;
};
var deserializeAws_queryGetSMSSandboxAccountStatusResult = function (output, context) {
    var contents = {
        IsInSandbox: undefined,
    };
    if (output["IsInSandbox"] !== undefined) {
        contents.IsInSandbox = __parseBoolean(output["IsInSandbox"]);
    }
    return contents;
};
var deserializeAws_queryGetSubscriptionAttributesResponse = function (output, context) {
    var contents = {
        Attributes: undefined,
    };
    if (output.Attributes === "") {
        contents.Attributes = {};
    }
    if (output["Attributes"] !== undefined && output["Attributes"]["entry"] !== undefined) {
        contents.Attributes = deserializeAws_querySubscriptionAttributesMap(__getArrayIfSingleItem(output["Attributes"]["entry"]), context);
    }
    return contents;
};
var deserializeAws_queryGetTopicAttributesResponse = function (output, context) {
    var contents = {
        Attributes: undefined,
    };
    if (output.Attributes === "") {
        contents.Attributes = {};
    }
    if (output["Attributes"] !== undefined && output["Attributes"]["entry"] !== undefined) {
        contents.Attributes = deserializeAws_queryTopicAttributesMap(__getArrayIfSingleItem(output["Attributes"]["entry"]), context);
    }
    return contents;
};
var deserializeAws_queryInternalErrorException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryInvalidBatchEntryIdException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryInvalidParameterException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryInvalidParameterValueException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryInvalidSecurityException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryKMSAccessDeniedException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryKMSDisabledException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryKMSInvalidStateException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryKMSNotFoundException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryKMSOptInRequired = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryKMSThrottlingException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryListEndpointsByPlatformApplicationResponse = function (output, context) {
    var contents = {
        Endpoints: undefined,
        NextToken: undefined,
    };
    if (output.Endpoints === "") {
        contents.Endpoints = [];
    }
    if (output["Endpoints"] !== undefined && output["Endpoints"]["member"] !== undefined) {
        contents.Endpoints = deserializeAws_queryListOfEndpoints(__getArrayIfSingleItem(output["Endpoints"]["member"]), context);
    }
    if (output["NextToken"] !== undefined) {
        contents.NextToken = __expectString(output["NextToken"]);
    }
    return contents;
};
var deserializeAws_queryListOfEndpoints = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_queryEndpoint(entry, context);
    });
};
var deserializeAws_queryListOfPlatformApplications = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_queryPlatformApplication(entry, context);
    });
};
var deserializeAws_queryListOriginationNumbersResult = function (output, context) {
    var contents = {
        NextToken: undefined,
        PhoneNumbers: undefined,
    };
    if (output["NextToken"] !== undefined) {
        contents.NextToken = __expectString(output["NextToken"]);
    }
    if (output.PhoneNumbers === "") {
        contents.PhoneNumbers = [];
    }
    if (output["PhoneNumbers"] !== undefined && output["PhoneNumbers"]["member"] !== undefined) {
        contents.PhoneNumbers = deserializeAws_queryPhoneNumberInformationList(__getArrayIfSingleItem(output["PhoneNumbers"]["member"]), context);
    }
    return contents;
};
var deserializeAws_queryListPhoneNumbersOptedOutResponse = function (output, context) {
    var contents = {
        phoneNumbers: undefined,
        nextToken: undefined,
    };
    if (output.phoneNumbers === "") {
        contents.phoneNumbers = [];
    }
    if (output["phoneNumbers"] !== undefined && output["phoneNumbers"]["member"] !== undefined) {
        contents.phoneNumbers = deserializeAws_queryPhoneNumberList(__getArrayIfSingleItem(output["phoneNumbers"]["member"]), context);
    }
    if (output["nextToken"] !== undefined) {
        contents.nextToken = __expectString(output["nextToken"]);
    }
    return contents;
};
var deserializeAws_queryListPlatformApplicationsResponse = function (output, context) {
    var contents = {
        PlatformApplications: undefined,
        NextToken: undefined,
    };
    if (output.PlatformApplications === "") {
        contents.PlatformApplications = [];
    }
    if (output["PlatformApplications"] !== undefined && output["PlatformApplications"]["member"] !== undefined) {
        contents.PlatformApplications = deserializeAws_queryListOfPlatformApplications(__getArrayIfSingleItem(output["PlatformApplications"]["member"]), context);
    }
    if (output["NextToken"] !== undefined) {
        contents.NextToken = __expectString(output["NextToken"]);
    }
    return contents;
};
var deserializeAws_queryListSMSSandboxPhoneNumbersResult = function (output, context) {
    var contents = {
        PhoneNumbers: undefined,
        NextToken: undefined,
    };
    if (output.PhoneNumbers === "") {
        contents.PhoneNumbers = [];
    }
    if (output["PhoneNumbers"] !== undefined && output["PhoneNumbers"]["member"] !== undefined) {
        contents.PhoneNumbers = deserializeAws_querySMSSandboxPhoneNumberList(__getArrayIfSingleItem(output["PhoneNumbers"]["member"]), context);
    }
    if (output["NextToken"] !== undefined) {
        contents.NextToken = __expectString(output["NextToken"]);
    }
    return contents;
};
var deserializeAws_queryListSubscriptionsByTopicResponse = function (output, context) {
    var contents = {
        Subscriptions: undefined,
        NextToken: undefined,
    };
    if (output.Subscriptions === "") {
        contents.Subscriptions = [];
    }
    if (output["Subscriptions"] !== undefined && output["Subscriptions"]["member"] !== undefined) {
        contents.Subscriptions = deserializeAws_querySubscriptionsList(__getArrayIfSingleItem(output["Subscriptions"]["member"]), context);
    }
    if (output["NextToken"] !== undefined) {
        contents.NextToken = __expectString(output["NextToken"]);
    }
    return contents;
};
var deserializeAws_queryListSubscriptionsResponse = function (output, context) {
    var contents = {
        Subscriptions: undefined,
        NextToken: undefined,
    };
    if (output.Subscriptions === "") {
        contents.Subscriptions = [];
    }
    if (output["Subscriptions"] !== undefined && output["Subscriptions"]["member"] !== undefined) {
        contents.Subscriptions = deserializeAws_querySubscriptionsList(__getArrayIfSingleItem(output["Subscriptions"]["member"]), context);
    }
    if (output["NextToken"] !== undefined) {
        contents.NextToken = __expectString(output["NextToken"]);
    }
    return contents;
};
var deserializeAws_queryListTagsForResourceResponse = function (output, context) {
    var contents = {
        Tags: undefined,
    };
    if (output.Tags === "") {
        contents.Tags = [];
    }
    if (output["Tags"] !== undefined && output["Tags"]["member"] !== undefined) {
        contents.Tags = deserializeAws_queryTagList(__getArrayIfSingleItem(output["Tags"]["member"]), context);
    }
    return contents;
};
var deserializeAws_queryListTopicsResponse = function (output, context) {
    var contents = {
        Topics: undefined,
        NextToken: undefined,
    };
    if (output.Topics === "") {
        contents.Topics = [];
    }
    if (output["Topics"] !== undefined && output["Topics"]["member"] !== undefined) {
        contents.Topics = deserializeAws_queryTopicsList(__getArrayIfSingleItem(output["Topics"]["member"]), context);
    }
    if (output["NextToken"] !== undefined) {
        contents.NextToken = __expectString(output["NextToken"]);
    }
    return contents;
};
var deserializeAws_queryMapStringToString = function (output, context) {
    return output.reduce(function (acc, pair) {
        var _a;
        if (pair["value"] === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_a = {}, _a[pair["key"]] = __expectString(pair["value"]), _a));
    }, {});
};
var deserializeAws_queryNotFoundException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryNumberCapabilityList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_queryOptedOutException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryOptInPhoneNumberResponse = function (output, context) {
    var contents = {};
    return contents;
};
var deserializeAws_queryPhoneNumberInformation = function (output, context) {
    var contents = {
        CreatedAt: undefined,
        PhoneNumber: undefined,
        Status: undefined,
        Iso2CountryCode: undefined,
        RouteType: undefined,
        NumberCapabilities: undefined,
    };
    if (output["CreatedAt"] !== undefined) {
        contents.CreatedAt = __expectNonNull(__parseRfc3339DateTime(output["CreatedAt"]));
    }
    if (output["PhoneNumber"] !== undefined) {
        contents.PhoneNumber = __expectString(output["PhoneNumber"]);
    }
    if (output["Status"] !== undefined) {
        contents.Status = __expectString(output["Status"]);
    }
    if (output["Iso2CountryCode"] !== undefined) {
        contents.Iso2CountryCode = __expectString(output["Iso2CountryCode"]);
    }
    if (output["RouteType"] !== undefined) {
        contents.RouteType = __expectString(output["RouteType"]);
    }
    if (output.NumberCapabilities === "") {
        contents.NumberCapabilities = [];
    }
    if (output["NumberCapabilities"] !== undefined && output["NumberCapabilities"]["member"] !== undefined) {
        contents.NumberCapabilities = deserializeAws_queryNumberCapabilityList(__getArrayIfSingleItem(output["NumberCapabilities"]["member"]), context);
    }
    return contents;
};
var deserializeAws_queryPhoneNumberInformationList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_queryPhoneNumberInformation(entry, context);
    });
};
var deserializeAws_queryPhoneNumberList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_queryPlatformApplication = function (output, context) {
    var contents = {
        PlatformApplicationArn: undefined,
        Attributes: undefined,
    };
    if (output["PlatformApplicationArn"] !== undefined) {
        contents.PlatformApplicationArn = __expectString(output["PlatformApplicationArn"]);
    }
    if (output.Attributes === "") {
        contents.Attributes = {};
    }
    if (output["Attributes"] !== undefined && output["Attributes"]["entry"] !== undefined) {
        contents.Attributes = deserializeAws_queryMapStringToString(__getArrayIfSingleItem(output["Attributes"]["entry"]), context);
    }
    return contents;
};
var deserializeAws_queryPlatformApplicationDisabledException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryPublishBatchResponse = function (output, context) {
    var contents = {
        Successful: undefined,
        Failed: undefined,
    };
    if (output.Successful === "") {
        contents.Successful = [];
    }
    if (output["Successful"] !== undefined && output["Successful"]["member"] !== undefined) {
        contents.Successful = deserializeAws_queryPublishBatchResultEntryList(__getArrayIfSingleItem(output["Successful"]["member"]), context);
    }
    if (output.Failed === "") {
        contents.Failed = [];
    }
    if (output["Failed"] !== undefined && output["Failed"]["member"] !== undefined) {
        contents.Failed = deserializeAws_queryBatchResultErrorEntryList(__getArrayIfSingleItem(output["Failed"]["member"]), context);
    }
    return contents;
};
var deserializeAws_queryPublishBatchResultEntry = function (output, context) {
    var contents = {
        Id: undefined,
        MessageId: undefined,
        SequenceNumber: undefined,
    };
    if (output["Id"] !== undefined) {
        contents.Id = __expectString(output["Id"]);
    }
    if (output["MessageId"] !== undefined) {
        contents.MessageId = __expectString(output["MessageId"]);
    }
    if (output["SequenceNumber"] !== undefined) {
        contents.SequenceNumber = __expectString(output["SequenceNumber"]);
    }
    return contents;
};
var deserializeAws_queryPublishBatchResultEntryList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_queryPublishBatchResultEntry(entry, context);
    });
};
var deserializeAws_queryPublishResponse = function (output, context) {
    var contents = {
        MessageId: undefined,
        SequenceNumber: undefined,
    };
    if (output["MessageId"] !== undefined) {
        contents.MessageId = __expectString(output["MessageId"]);
    }
    if (output["SequenceNumber"] !== undefined) {
        contents.SequenceNumber = __expectString(output["SequenceNumber"]);
    }
    return contents;
};
var deserializeAws_queryResourceNotFoundException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_querySetSMSAttributesResponse = function (output, context) {
    var contents = {};
    return contents;
};
var deserializeAws_querySMSSandboxPhoneNumber = function (output, context) {
    var contents = {
        PhoneNumber: undefined,
        Status: undefined,
    };
    if (output["PhoneNumber"] !== undefined) {
        contents.PhoneNumber = __expectString(output["PhoneNumber"]);
    }
    if (output["Status"] !== undefined) {
        contents.Status = __expectString(output["Status"]);
    }
    return contents;
};
var deserializeAws_querySMSSandboxPhoneNumberList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_querySMSSandboxPhoneNumber(entry, context);
    });
};
var deserializeAws_queryStaleTagException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_querySubscribeResponse = function (output, context) {
    var contents = {
        SubscriptionArn: undefined,
    };
    if (output["SubscriptionArn"] !== undefined) {
        contents.SubscriptionArn = __expectString(output["SubscriptionArn"]);
    }
    return contents;
};
var deserializeAws_querySubscription = function (output, context) {
    var contents = {
        SubscriptionArn: undefined,
        Owner: undefined,
        Protocol: undefined,
        Endpoint: undefined,
        TopicArn: undefined,
    };
    if (output["SubscriptionArn"] !== undefined) {
        contents.SubscriptionArn = __expectString(output["SubscriptionArn"]);
    }
    if (output["Owner"] !== undefined) {
        contents.Owner = __expectString(output["Owner"]);
    }
    if (output["Protocol"] !== undefined) {
        contents.Protocol = __expectString(output["Protocol"]);
    }
    if (output["Endpoint"] !== undefined) {
        contents.Endpoint = __expectString(output["Endpoint"]);
    }
    if (output["TopicArn"] !== undefined) {
        contents.TopicArn = __expectString(output["TopicArn"]);
    }
    return contents;
};
var deserializeAws_querySubscriptionAttributesMap = function (output, context) {
    return output.reduce(function (acc, pair) {
        var _a;
        if (pair["value"] === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_a = {}, _a[pair["key"]] = __expectString(pair["value"]), _a));
    }, {});
};
var deserializeAws_querySubscriptionLimitExceededException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_querySubscriptionsList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_querySubscription(entry, context);
    });
};
var deserializeAws_queryTag = function (output, context) {
    var contents = {
        Key: undefined,
        Value: undefined,
    };
    if (output["Key"] !== undefined) {
        contents.Key = __expectString(output["Key"]);
    }
    if (output["Value"] !== undefined) {
        contents.Value = __expectString(output["Value"]);
    }
    return contents;
};
var deserializeAws_queryTagLimitExceededException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryTagList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_queryTag(entry, context);
    });
};
var deserializeAws_queryTagPolicyException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryTagResourceResponse = function (output, context) {
    var contents = {};
    return contents;
};
var deserializeAws_queryThrottledException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryTooManyEntriesInBatchRequestException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryTopic = function (output, context) {
    var contents = {
        TopicArn: undefined,
    };
    if (output["TopicArn"] !== undefined) {
        contents.TopicArn = __expectString(output["TopicArn"]);
    }
    return contents;
};
var deserializeAws_queryTopicAttributesMap = function (output, context) {
    return output.reduce(function (acc, pair) {
        var _a;
        if (pair["value"] === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_a = {}, _a[pair["key"]] = __expectString(pair["value"]), _a));
    }, {});
};
var deserializeAws_queryTopicLimitExceededException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryTopicsList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_queryTopic(entry, context);
    });
};
var deserializeAws_queryUntagResourceResponse = function (output, context) {
    var contents = {};
    return contents;
};
var deserializeAws_queryUserErrorException = function (output, context) {
    var contents = {
        message: undefined,
    };
    if (output["message"] !== undefined) {
        contents.message = __expectString(output["message"]);
    }
    return contents;
};
var deserializeAws_queryValidationException = function (output, context) {
    var contents = {
        Message: undefined,
    };
    if (output["Message"] !== undefined) {
        contents.Message = __expectString(output["Message"]);
    }
    return contents;
};
var deserializeAws_queryVerificationException = function (output, context) {
    var contents = {
        Message: undefined,
        Status: undefined,
    };
    if (output["Message"] !== undefined) {
        contents.Message = __expectString(output["Message"]);
    }
    if (output["Status"] !== undefined) {
        contents.Status = __expectString(output["Status"]);
    }
    return contents;
};
var deserializeAws_queryVerifySMSSandboxPhoneNumberResult = function (output, context) {
    var contents = {};
    return contents;
};
var deserializeMetadata = function (output) {
    var _a;
    return ({
        httpStatusCode: output.statusCode,
        requestId: (_a = output.headers["x-amzn-requestid"]) !== null && _a !== void 0 ? _a : output.headers["x-amzn-request-id"],
        extendedRequestId: output.headers["x-amz-id-2"],
        cfId: output.headers["x-amz-cf-id"],
    });
};
var collectBody = function (streamBody, context) {
    if (streamBody === void 0) { streamBody = new Uint8Array(); }
    if (streamBody instanceof Uint8Array) {
        return Promise.resolve(streamBody);
    }
    return context.streamCollector(streamBody) || Promise.resolve(new Uint8Array());
};
var collectBodyString = function (streamBody, context) {
    return collectBody(streamBody, context).then(function (body) { return context.utf8Encoder(body); });
};
var buildHttpRpcRequest = function (context, headers, path, resolvedHostname, body) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                contents = {
                    protocol: protocol,
                    hostname: hostname,
                    port: port,
                    method: "POST",
                    path: basePath.endsWith("/") ? basePath.slice(0, -1) + path : basePath + path,
                    headers: headers,
                };
                if (resolvedHostname !== undefined) {
                    contents.hostname = resolvedHostname;
                }
                if (body !== undefined) {
                    contents.body = body;
                }
                return [2, new __HttpRequest(contents)];
        }
    });
}); };
var parseBody = function (streamBody, context) {
    return collectBodyString(streamBody, context).then(function (encoded) {
        if (encoded.length) {
            var parsedObj = xmlParse(encoded, {
                attributeNamePrefix: "",
                ignoreAttributes: false,
                parseNodeValue: false,
                trimValues: false,
                tagValueProcessor: function (val) { return (val.trim() === "" && val.includes("\n") ? "" : decodeHTML(val)); },
            });
            var textNodeName = "#text";
            var key = Object.keys(parsedObj)[0];
            var parsedObjToReturn = parsedObj[key];
            if (parsedObjToReturn[textNodeName]) {
                parsedObjToReturn[key] = parsedObjToReturn[textNodeName];
                delete parsedObjToReturn[textNodeName];
            }
            return __getValueFromTextNode(parsedObjToReturn);
        }
        return {};
    });
};
var buildFormUrlencodedString = function (formEntries) {
    return Object.entries(formEntries)
        .map(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        return __extendedEncodeURIComponent(key) + "=" + __extendedEncodeURIComponent(value);
    })
        .join("&");
};
var loadQueryErrorCode = function (output, data) {
    if (data.Error.Code !== undefined) {
        return data.Error.Code;
    }
    if (output.statusCode == 404) {
        return "NotFound";
    }
    return "";
};
