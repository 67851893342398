import { __assign, __extends } from "tslib";
import { SNSServiceException as __BaseException } from "./SNSServiceException";
export var AddPermissionInput;
(function (AddPermissionInput) {
    AddPermissionInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AddPermissionInput || (AddPermissionInput = {}));
var AuthorizationErrorException = (function (_super) {
    __extends(AuthorizationErrorException, _super);
    function AuthorizationErrorException(opts) {
        var _this = _super.call(this, __assign({ name: "AuthorizationErrorException", $fault: "client" }, opts)) || this;
        _this.name = "AuthorizationErrorException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, AuthorizationErrorException.prototype);
        return _this;
    }
    return AuthorizationErrorException;
}(__BaseException));
export { AuthorizationErrorException };
var InternalErrorException = (function (_super) {
    __extends(InternalErrorException, _super);
    function InternalErrorException(opts) {
        var _this = _super.call(this, __assign({ name: "InternalErrorException", $fault: "server" }, opts)) || this;
        _this.name = "InternalErrorException";
        _this.$fault = "server";
        Object.setPrototypeOf(_this, InternalErrorException.prototype);
        return _this;
    }
    return InternalErrorException;
}(__BaseException));
export { InternalErrorException };
var InvalidParameterException = (function (_super) {
    __extends(InvalidParameterException, _super);
    function InvalidParameterException(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidParameterException", $fault: "client" }, opts)) || this;
        _this.name = "InvalidParameterException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidParameterException.prototype);
        return _this;
    }
    return InvalidParameterException;
}(__BaseException));
export { InvalidParameterException };
var NotFoundException = (function (_super) {
    __extends(NotFoundException, _super);
    function NotFoundException(opts) {
        var _this = _super.call(this, __assign({ name: "NotFoundException", $fault: "client" }, opts)) || this;
        _this.name = "NotFoundException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, NotFoundException.prototype);
        return _this;
    }
    return NotFoundException;
}(__BaseException));
export { NotFoundException };
export var CheckIfPhoneNumberIsOptedOutInput;
(function (CheckIfPhoneNumberIsOptedOutInput) {
    CheckIfPhoneNumberIsOptedOutInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CheckIfPhoneNumberIsOptedOutInput || (CheckIfPhoneNumberIsOptedOutInput = {}));
export var CheckIfPhoneNumberIsOptedOutResponse;
(function (CheckIfPhoneNumberIsOptedOutResponse) {
    CheckIfPhoneNumberIsOptedOutResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CheckIfPhoneNumberIsOptedOutResponse || (CheckIfPhoneNumberIsOptedOutResponse = {}));
var ThrottledException = (function (_super) {
    __extends(ThrottledException, _super);
    function ThrottledException(opts) {
        var _this = _super.call(this, __assign({ name: "ThrottledException", $fault: "client" }, opts)) || this;
        _this.name = "ThrottledException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ThrottledException.prototype);
        return _this;
    }
    return ThrottledException;
}(__BaseException));
export { ThrottledException };
export var ConfirmSubscriptionInput;
(function (ConfirmSubscriptionInput) {
    ConfirmSubscriptionInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ConfirmSubscriptionInput || (ConfirmSubscriptionInput = {}));
export var ConfirmSubscriptionResponse;
(function (ConfirmSubscriptionResponse) {
    ConfirmSubscriptionResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ConfirmSubscriptionResponse || (ConfirmSubscriptionResponse = {}));
var FilterPolicyLimitExceededException = (function (_super) {
    __extends(FilterPolicyLimitExceededException, _super);
    function FilterPolicyLimitExceededException(opts) {
        var _this = _super.call(this, __assign({ name: "FilterPolicyLimitExceededException", $fault: "client" }, opts)) || this;
        _this.name = "FilterPolicyLimitExceededException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, FilterPolicyLimitExceededException.prototype);
        return _this;
    }
    return FilterPolicyLimitExceededException;
}(__BaseException));
export { FilterPolicyLimitExceededException };
var SubscriptionLimitExceededException = (function (_super) {
    __extends(SubscriptionLimitExceededException, _super);
    function SubscriptionLimitExceededException(opts) {
        var _this = _super.call(this, __assign({ name: "SubscriptionLimitExceededException", $fault: "client" }, opts)) || this;
        _this.name = "SubscriptionLimitExceededException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, SubscriptionLimitExceededException.prototype);
        return _this;
    }
    return SubscriptionLimitExceededException;
}(__BaseException));
export { SubscriptionLimitExceededException };
export var CreatePlatformApplicationInput;
(function (CreatePlatformApplicationInput) {
    CreatePlatformApplicationInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreatePlatformApplicationInput || (CreatePlatformApplicationInput = {}));
export var CreatePlatformApplicationResponse;
(function (CreatePlatformApplicationResponse) {
    CreatePlatformApplicationResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreatePlatformApplicationResponse || (CreatePlatformApplicationResponse = {}));
export var CreateEndpointResponse;
(function (CreateEndpointResponse) {
    CreateEndpointResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateEndpointResponse || (CreateEndpointResponse = {}));
export var CreatePlatformEndpointInput;
(function (CreatePlatformEndpointInput) {
    CreatePlatformEndpointInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreatePlatformEndpointInput || (CreatePlatformEndpointInput = {}));
export var LanguageCodeString;
(function (LanguageCodeString) {
    LanguageCodeString["de_DE"] = "de-DE";
    LanguageCodeString["en_GB"] = "en-GB";
    LanguageCodeString["en_US"] = "en-US";
    LanguageCodeString["es_419"] = "es-419";
    LanguageCodeString["es_ES"] = "es-ES";
    LanguageCodeString["fr_CA"] = "fr-CA";
    LanguageCodeString["fr_FR"] = "fr-FR";
    LanguageCodeString["it_IT"] = "it-IT";
    LanguageCodeString["jp_JP"] = "ja-JP";
    LanguageCodeString["kr_KR"] = "kr-KR";
    LanguageCodeString["pt_BR"] = "pt-BR";
    LanguageCodeString["zh_CN"] = "zh-CN";
    LanguageCodeString["zh_TW"] = "zh-TW";
})(LanguageCodeString || (LanguageCodeString = {}));
export var CreateSMSSandboxPhoneNumberInput;
(function (CreateSMSSandboxPhoneNumberInput) {
    CreateSMSSandboxPhoneNumberInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateSMSSandboxPhoneNumberInput || (CreateSMSSandboxPhoneNumberInput = {}));
export var CreateSMSSandboxPhoneNumberResult;
(function (CreateSMSSandboxPhoneNumberResult) {
    CreateSMSSandboxPhoneNumberResult.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateSMSSandboxPhoneNumberResult || (CreateSMSSandboxPhoneNumberResult = {}));
var OptedOutException = (function (_super) {
    __extends(OptedOutException, _super);
    function OptedOutException(opts) {
        var _this = _super.call(this, __assign({ name: "OptedOutException", $fault: "client" }, opts)) || this;
        _this.name = "OptedOutException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, OptedOutException.prototype);
        return _this;
    }
    return OptedOutException;
}(__BaseException));
export { OptedOutException };
var UserErrorException = (function (_super) {
    __extends(UserErrorException, _super);
    function UserErrorException(opts) {
        var _this = _super.call(this, __assign({ name: "UserErrorException", $fault: "client" }, opts)) || this;
        _this.name = "UserErrorException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, UserErrorException.prototype);
        return _this;
    }
    return UserErrorException;
}(__BaseException));
export { UserErrorException };
var ConcurrentAccessException = (function (_super) {
    __extends(ConcurrentAccessException, _super);
    function ConcurrentAccessException(opts) {
        var _this = _super.call(this, __assign({ name: "ConcurrentAccessException", $fault: "client" }, opts)) || this;
        _this.name = "ConcurrentAccessException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ConcurrentAccessException.prototype);
        return _this;
    }
    return ConcurrentAccessException;
}(__BaseException));
export { ConcurrentAccessException };
export var Tag;
(function (Tag) {
    Tag.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(Tag || (Tag = {}));
export var CreateTopicInput;
(function (CreateTopicInput) {
    CreateTopicInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateTopicInput || (CreateTopicInput = {}));
export var CreateTopicResponse;
(function (CreateTopicResponse) {
    CreateTopicResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateTopicResponse || (CreateTopicResponse = {}));
var InvalidSecurityException = (function (_super) {
    __extends(InvalidSecurityException, _super);
    function InvalidSecurityException(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidSecurityException", $fault: "client" }, opts)) || this;
        _this.name = "InvalidSecurityException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidSecurityException.prototype);
        return _this;
    }
    return InvalidSecurityException;
}(__BaseException));
export { InvalidSecurityException };
var StaleTagException = (function (_super) {
    __extends(StaleTagException, _super);
    function StaleTagException(opts) {
        var _this = _super.call(this, __assign({ name: "StaleTagException", $fault: "client" }, opts)) || this;
        _this.name = "StaleTagException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, StaleTagException.prototype);
        return _this;
    }
    return StaleTagException;
}(__BaseException));
export { StaleTagException };
var TagLimitExceededException = (function (_super) {
    __extends(TagLimitExceededException, _super);
    function TagLimitExceededException(opts) {
        var _this = _super.call(this, __assign({ name: "TagLimitExceededException", $fault: "client" }, opts)) || this;
        _this.name = "TagLimitExceededException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, TagLimitExceededException.prototype);
        return _this;
    }
    return TagLimitExceededException;
}(__BaseException));
export { TagLimitExceededException };
var TagPolicyException = (function (_super) {
    __extends(TagPolicyException, _super);
    function TagPolicyException(opts) {
        var _this = _super.call(this, __assign({ name: "TagPolicyException", $fault: "client" }, opts)) || this;
        _this.name = "TagPolicyException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, TagPolicyException.prototype);
        return _this;
    }
    return TagPolicyException;
}(__BaseException));
export { TagPolicyException };
var TopicLimitExceededException = (function (_super) {
    __extends(TopicLimitExceededException, _super);
    function TopicLimitExceededException(opts) {
        var _this = _super.call(this, __assign({ name: "TopicLimitExceededException", $fault: "client" }, opts)) || this;
        _this.name = "TopicLimitExceededException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, TopicLimitExceededException.prototype);
        return _this;
    }
    return TopicLimitExceededException;
}(__BaseException));
export { TopicLimitExceededException };
export var DeleteEndpointInput;
(function (DeleteEndpointInput) {
    DeleteEndpointInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteEndpointInput || (DeleteEndpointInput = {}));
export var DeletePlatformApplicationInput;
(function (DeletePlatformApplicationInput) {
    DeletePlatformApplicationInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeletePlatformApplicationInput || (DeletePlatformApplicationInput = {}));
export var DeleteSMSSandboxPhoneNumberInput;
(function (DeleteSMSSandboxPhoneNumberInput) {
    DeleteSMSSandboxPhoneNumberInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteSMSSandboxPhoneNumberInput || (DeleteSMSSandboxPhoneNumberInput = {}));
export var DeleteSMSSandboxPhoneNumberResult;
(function (DeleteSMSSandboxPhoneNumberResult) {
    DeleteSMSSandboxPhoneNumberResult.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteSMSSandboxPhoneNumberResult || (DeleteSMSSandboxPhoneNumberResult = {}));
var ResourceNotFoundException = (function (_super) {
    __extends(ResourceNotFoundException, _super);
    function ResourceNotFoundException(opts) {
        var _this = _super.call(this, __assign({ name: "ResourceNotFoundException", $fault: "client" }, opts)) || this;
        _this.name = "ResourceNotFoundException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ResourceNotFoundException.prototype);
        return _this;
    }
    return ResourceNotFoundException;
}(__BaseException));
export { ResourceNotFoundException };
export var DeleteTopicInput;
(function (DeleteTopicInput) {
    DeleteTopicInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteTopicInput || (DeleteTopicInput = {}));
export var GetEndpointAttributesInput;
(function (GetEndpointAttributesInput) {
    GetEndpointAttributesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetEndpointAttributesInput || (GetEndpointAttributesInput = {}));
export var GetEndpointAttributesResponse;
(function (GetEndpointAttributesResponse) {
    GetEndpointAttributesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetEndpointAttributesResponse || (GetEndpointAttributesResponse = {}));
export var GetPlatformApplicationAttributesInput;
(function (GetPlatformApplicationAttributesInput) {
    GetPlatformApplicationAttributesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetPlatformApplicationAttributesInput || (GetPlatformApplicationAttributesInput = {}));
export var GetPlatformApplicationAttributesResponse;
(function (GetPlatformApplicationAttributesResponse) {
    GetPlatformApplicationAttributesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetPlatformApplicationAttributesResponse || (GetPlatformApplicationAttributesResponse = {}));
export var GetSMSAttributesInput;
(function (GetSMSAttributesInput) {
    GetSMSAttributesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSMSAttributesInput || (GetSMSAttributesInput = {}));
export var GetSMSAttributesResponse;
(function (GetSMSAttributesResponse) {
    GetSMSAttributesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSMSAttributesResponse || (GetSMSAttributesResponse = {}));
export var GetSMSSandboxAccountStatusInput;
(function (GetSMSSandboxAccountStatusInput) {
    GetSMSSandboxAccountStatusInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSMSSandboxAccountStatusInput || (GetSMSSandboxAccountStatusInput = {}));
export var GetSMSSandboxAccountStatusResult;
(function (GetSMSSandboxAccountStatusResult) {
    GetSMSSandboxAccountStatusResult.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSMSSandboxAccountStatusResult || (GetSMSSandboxAccountStatusResult = {}));
export var GetSubscriptionAttributesInput;
(function (GetSubscriptionAttributesInput) {
    GetSubscriptionAttributesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSubscriptionAttributesInput || (GetSubscriptionAttributesInput = {}));
export var GetSubscriptionAttributesResponse;
(function (GetSubscriptionAttributesResponse) {
    GetSubscriptionAttributesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSubscriptionAttributesResponse || (GetSubscriptionAttributesResponse = {}));
export var GetTopicAttributesInput;
(function (GetTopicAttributesInput) {
    GetTopicAttributesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetTopicAttributesInput || (GetTopicAttributesInput = {}));
export var GetTopicAttributesResponse;
(function (GetTopicAttributesResponse) {
    GetTopicAttributesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetTopicAttributesResponse || (GetTopicAttributesResponse = {}));
export var ListEndpointsByPlatformApplicationInput;
(function (ListEndpointsByPlatformApplicationInput) {
    ListEndpointsByPlatformApplicationInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListEndpointsByPlatformApplicationInput || (ListEndpointsByPlatformApplicationInput = {}));
export var Endpoint;
(function (Endpoint) {
    Endpoint.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(Endpoint || (Endpoint = {}));
export var ListEndpointsByPlatformApplicationResponse;
(function (ListEndpointsByPlatformApplicationResponse) {
    ListEndpointsByPlatformApplicationResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListEndpointsByPlatformApplicationResponse || (ListEndpointsByPlatformApplicationResponse = {}));
export var ListOriginationNumbersRequest;
(function (ListOriginationNumbersRequest) {
    ListOriginationNumbersRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListOriginationNumbersRequest || (ListOriginationNumbersRequest = {}));
export var NumberCapability;
(function (NumberCapability) {
    NumberCapability["MMS"] = "MMS";
    NumberCapability["SMS"] = "SMS";
    NumberCapability["VOICE"] = "VOICE";
})(NumberCapability || (NumberCapability = {}));
export var RouteType;
(function (RouteType) {
    RouteType["Premium"] = "Premium";
    RouteType["Promotional"] = "Promotional";
    RouteType["Transactional"] = "Transactional";
})(RouteType || (RouteType = {}));
export var PhoneNumberInformation;
(function (PhoneNumberInformation) {
    PhoneNumberInformation.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PhoneNumberInformation || (PhoneNumberInformation = {}));
export var ListOriginationNumbersResult;
(function (ListOriginationNumbersResult) {
    ListOriginationNumbersResult.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListOriginationNumbersResult || (ListOriginationNumbersResult = {}));
var ValidationException = (function (_super) {
    __extends(ValidationException, _super);
    function ValidationException(opts) {
        var _this = _super.call(this, __assign({ name: "ValidationException", $fault: "client" }, opts)) || this;
        _this.name = "ValidationException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ValidationException.prototype);
        _this.Message = opts.Message;
        return _this;
    }
    return ValidationException;
}(__BaseException));
export { ValidationException };
export var ListPhoneNumbersOptedOutInput;
(function (ListPhoneNumbersOptedOutInput) {
    ListPhoneNumbersOptedOutInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListPhoneNumbersOptedOutInput || (ListPhoneNumbersOptedOutInput = {}));
export var ListPhoneNumbersOptedOutResponse;
(function (ListPhoneNumbersOptedOutResponse) {
    ListPhoneNumbersOptedOutResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListPhoneNumbersOptedOutResponse || (ListPhoneNumbersOptedOutResponse = {}));
export var ListPlatformApplicationsInput;
(function (ListPlatformApplicationsInput) {
    ListPlatformApplicationsInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListPlatformApplicationsInput || (ListPlatformApplicationsInput = {}));
export var PlatformApplication;
(function (PlatformApplication) {
    PlatformApplication.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PlatformApplication || (PlatformApplication = {}));
export var ListPlatformApplicationsResponse;
(function (ListPlatformApplicationsResponse) {
    ListPlatformApplicationsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListPlatformApplicationsResponse || (ListPlatformApplicationsResponse = {}));
export var ListSMSSandboxPhoneNumbersInput;
(function (ListSMSSandboxPhoneNumbersInput) {
    ListSMSSandboxPhoneNumbersInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListSMSSandboxPhoneNumbersInput || (ListSMSSandboxPhoneNumbersInput = {}));
export var SMSSandboxPhoneNumberVerificationStatus;
(function (SMSSandboxPhoneNumberVerificationStatus) {
    SMSSandboxPhoneNumberVerificationStatus["Pending"] = "Pending";
    SMSSandboxPhoneNumberVerificationStatus["Verified"] = "Verified";
})(SMSSandboxPhoneNumberVerificationStatus || (SMSSandboxPhoneNumberVerificationStatus = {}));
export var SMSSandboxPhoneNumber;
(function (SMSSandboxPhoneNumber) {
    SMSSandboxPhoneNumber.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SMSSandboxPhoneNumber || (SMSSandboxPhoneNumber = {}));
export var ListSMSSandboxPhoneNumbersResult;
(function (ListSMSSandboxPhoneNumbersResult) {
    ListSMSSandboxPhoneNumbersResult.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListSMSSandboxPhoneNumbersResult || (ListSMSSandboxPhoneNumbersResult = {}));
export var ListSubscriptionsInput;
(function (ListSubscriptionsInput) {
    ListSubscriptionsInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListSubscriptionsInput || (ListSubscriptionsInput = {}));
export var Subscription;
(function (Subscription) {
    Subscription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(Subscription || (Subscription = {}));
export var ListSubscriptionsResponse;
(function (ListSubscriptionsResponse) {
    ListSubscriptionsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListSubscriptionsResponse || (ListSubscriptionsResponse = {}));
export var ListSubscriptionsByTopicInput;
(function (ListSubscriptionsByTopicInput) {
    ListSubscriptionsByTopicInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListSubscriptionsByTopicInput || (ListSubscriptionsByTopicInput = {}));
export var ListSubscriptionsByTopicResponse;
(function (ListSubscriptionsByTopicResponse) {
    ListSubscriptionsByTopicResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListSubscriptionsByTopicResponse || (ListSubscriptionsByTopicResponse = {}));
export var ListTagsForResourceRequest;
(function (ListTagsForResourceRequest) {
    ListTagsForResourceRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTagsForResourceRequest || (ListTagsForResourceRequest = {}));
export var ListTagsForResourceResponse;
(function (ListTagsForResourceResponse) {
    ListTagsForResourceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTagsForResourceResponse || (ListTagsForResourceResponse = {}));
export var ListTopicsInput;
(function (ListTopicsInput) {
    ListTopicsInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTopicsInput || (ListTopicsInput = {}));
export var Topic;
(function (Topic) {
    Topic.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(Topic || (Topic = {}));
export var ListTopicsResponse;
(function (ListTopicsResponse) {
    ListTopicsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTopicsResponse || (ListTopicsResponse = {}));
export var OptInPhoneNumberInput;
(function (OptInPhoneNumberInput) {
    OptInPhoneNumberInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(OptInPhoneNumberInput || (OptInPhoneNumberInput = {}));
export var OptInPhoneNumberResponse;
(function (OptInPhoneNumberResponse) {
    OptInPhoneNumberResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(OptInPhoneNumberResponse || (OptInPhoneNumberResponse = {}));
var EndpointDisabledException = (function (_super) {
    __extends(EndpointDisabledException, _super);
    function EndpointDisabledException(opts) {
        var _this = _super.call(this, __assign({ name: "EndpointDisabledException", $fault: "client" }, opts)) || this;
        _this.name = "EndpointDisabledException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, EndpointDisabledException.prototype);
        return _this;
    }
    return EndpointDisabledException;
}(__BaseException));
export { EndpointDisabledException };
var InvalidParameterValueException = (function (_super) {
    __extends(InvalidParameterValueException, _super);
    function InvalidParameterValueException(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidParameterValueException", $fault: "client" }, opts)) || this;
        _this.name = "InvalidParameterValueException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidParameterValueException.prototype);
        return _this;
    }
    return InvalidParameterValueException;
}(__BaseException));
export { InvalidParameterValueException };
var KMSAccessDeniedException = (function (_super) {
    __extends(KMSAccessDeniedException, _super);
    function KMSAccessDeniedException(opts) {
        var _this = _super.call(this, __assign({ name: "KMSAccessDeniedException", $fault: "client" }, opts)) || this;
        _this.name = "KMSAccessDeniedException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, KMSAccessDeniedException.prototype);
        return _this;
    }
    return KMSAccessDeniedException;
}(__BaseException));
export { KMSAccessDeniedException };
var KMSDisabledException = (function (_super) {
    __extends(KMSDisabledException, _super);
    function KMSDisabledException(opts) {
        var _this = _super.call(this, __assign({ name: "KMSDisabledException", $fault: "client" }, opts)) || this;
        _this.name = "KMSDisabledException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, KMSDisabledException.prototype);
        return _this;
    }
    return KMSDisabledException;
}(__BaseException));
export { KMSDisabledException };
var KMSInvalidStateException = (function (_super) {
    __extends(KMSInvalidStateException, _super);
    function KMSInvalidStateException(opts) {
        var _this = _super.call(this, __assign({ name: "KMSInvalidStateException", $fault: "client" }, opts)) || this;
        _this.name = "KMSInvalidStateException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, KMSInvalidStateException.prototype);
        return _this;
    }
    return KMSInvalidStateException;
}(__BaseException));
export { KMSInvalidStateException };
var KMSNotFoundException = (function (_super) {
    __extends(KMSNotFoundException, _super);
    function KMSNotFoundException(opts) {
        var _this = _super.call(this, __assign({ name: "KMSNotFoundException", $fault: "client" }, opts)) || this;
        _this.name = "KMSNotFoundException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, KMSNotFoundException.prototype);
        return _this;
    }
    return KMSNotFoundException;
}(__BaseException));
export { KMSNotFoundException };
var KMSOptInRequired = (function (_super) {
    __extends(KMSOptInRequired, _super);
    function KMSOptInRequired(opts) {
        var _this = _super.call(this, __assign({ name: "KMSOptInRequired", $fault: "client" }, opts)) || this;
        _this.name = "KMSOptInRequired";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, KMSOptInRequired.prototype);
        return _this;
    }
    return KMSOptInRequired;
}(__BaseException));
export { KMSOptInRequired };
var KMSThrottlingException = (function (_super) {
    __extends(KMSThrottlingException, _super);
    function KMSThrottlingException(opts) {
        var _this = _super.call(this, __assign({ name: "KMSThrottlingException", $fault: "client" }, opts)) || this;
        _this.name = "KMSThrottlingException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, KMSThrottlingException.prototype);
        return _this;
    }
    return KMSThrottlingException;
}(__BaseException));
export { KMSThrottlingException };
var PlatformApplicationDisabledException = (function (_super) {
    __extends(PlatformApplicationDisabledException, _super);
    function PlatformApplicationDisabledException(opts) {
        var _this = _super.call(this, __assign({ name: "PlatformApplicationDisabledException", $fault: "client" }, opts)) || this;
        _this.name = "PlatformApplicationDisabledException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, PlatformApplicationDisabledException.prototype);
        return _this;
    }
    return PlatformApplicationDisabledException;
}(__BaseException));
export { PlatformApplicationDisabledException };
export var MessageAttributeValue;
(function (MessageAttributeValue) {
    MessageAttributeValue.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(MessageAttributeValue || (MessageAttributeValue = {}));
export var PublishInput;
(function (PublishInput) {
    PublishInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PublishInput || (PublishInput = {}));
export var PublishResponse;
(function (PublishResponse) {
    PublishResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PublishResponse || (PublishResponse = {}));
var BatchEntryIdsNotDistinctException = (function (_super) {
    __extends(BatchEntryIdsNotDistinctException, _super);
    function BatchEntryIdsNotDistinctException(opts) {
        var _this = _super.call(this, __assign({ name: "BatchEntryIdsNotDistinctException", $fault: "client" }, opts)) || this;
        _this.name = "BatchEntryIdsNotDistinctException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, BatchEntryIdsNotDistinctException.prototype);
        return _this;
    }
    return BatchEntryIdsNotDistinctException;
}(__BaseException));
export { BatchEntryIdsNotDistinctException };
var BatchRequestTooLongException = (function (_super) {
    __extends(BatchRequestTooLongException, _super);
    function BatchRequestTooLongException(opts) {
        var _this = _super.call(this, __assign({ name: "BatchRequestTooLongException", $fault: "client" }, opts)) || this;
        _this.name = "BatchRequestTooLongException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, BatchRequestTooLongException.prototype);
        return _this;
    }
    return BatchRequestTooLongException;
}(__BaseException));
export { BatchRequestTooLongException };
var EmptyBatchRequestException = (function (_super) {
    __extends(EmptyBatchRequestException, _super);
    function EmptyBatchRequestException(opts) {
        var _this = _super.call(this, __assign({ name: "EmptyBatchRequestException", $fault: "client" }, opts)) || this;
        _this.name = "EmptyBatchRequestException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, EmptyBatchRequestException.prototype);
        return _this;
    }
    return EmptyBatchRequestException;
}(__BaseException));
export { EmptyBatchRequestException };
var InvalidBatchEntryIdException = (function (_super) {
    __extends(InvalidBatchEntryIdException, _super);
    function InvalidBatchEntryIdException(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidBatchEntryIdException", $fault: "client" }, opts)) || this;
        _this.name = "InvalidBatchEntryIdException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidBatchEntryIdException.prototype);
        return _this;
    }
    return InvalidBatchEntryIdException;
}(__BaseException));
export { InvalidBatchEntryIdException };
export var PublishBatchRequestEntry;
(function (PublishBatchRequestEntry) {
    PublishBatchRequestEntry.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PublishBatchRequestEntry || (PublishBatchRequestEntry = {}));
export var PublishBatchInput;
(function (PublishBatchInput) {
    PublishBatchInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PublishBatchInput || (PublishBatchInput = {}));
export var BatchResultErrorEntry;
(function (BatchResultErrorEntry) {
    BatchResultErrorEntry.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchResultErrorEntry || (BatchResultErrorEntry = {}));
export var PublishBatchResultEntry;
(function (PublishBatchResultEntry) {
    PublishBatchResultEntry.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PublishBatchResultEntry || (PublishBatchResultEntry = {}));
export var PublishBatchResponse;
(function (PublishBatchResponse) {
    PublishBatchResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PublishBatchResponse || (PublishBatchResponse = {}));
var TooManyEntriesInBatchRequestException = (function (_super) {
    __extends(TooManyEntriesInBatchRequestException, _super);
    function TooManyEntriesInBatchRequestException(opts) {
        var _this = _super.call(this, __assign({ name: "TooManyEntriesInBatchRequestException", $fault: "client" }, opts)) || this;
        _this.name = "TooManyEntriesInBatchRequestException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, TooManyEntriesInBatchRequestException.prototype);
        return _this;
    }
    return TooManyEntriesInBatchRequestException;
}(__BaseException));
export { TooManyEntriesInBatchRequestException };
export var RemovePermissionInput;
(function (RemovePermissionInput) {
    RemovePermissionInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RemovePermissionInput || (RemovePermissionInput = {}));
export var SetEndpointAttributesInput;
(function (SetEndpointAttributesInput) {
    SetEndpointAttributesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SetEndpointAttributesInput || (SetEndpointAttributesInput = {}));
export var SetPlatformApplicationAttributesInput;
(function (SetPlatformApplicationAttributesInput) {
    SetPlatformApplicationAttributesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SetPlatformApplicationAttributesInput || (SetPlatformApplicationAttributesInput = {}));
export var SetSMSAttributesInput;
(function (SetSMSAttributesInput) {
    SetSMSAttributesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SetSMSAttributesInput || (SetSMSAttributesInput = {}));
export var SetSMSAttributesResponse;
(function (SetSMSAttributesResponse) {
    SetSMSAttributesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SetSMSAttributesResponse || (SetSMSAttributesResponse = {}));
export var SetSubscriptionAttributesInput;
(function (SetSubscriptionAttributesInput) {
    SetSubscriptionAttributesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SetSubscriptionAttributesInput || (SetSubscriptionAttributesInput = {}));
export var SetTopicAttributesInput;
(function (SetTopicAttributesInput) {
    SetTopicAttributesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SetTopicAttributesInput || (SetTopicAttributesInput = {}));
export var SubscribeInput;
(function (SubscribeInput) {
    SubscribeInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SubscribeInput || (SubscribeInput = {}));
export var SubscribeResponse;
(function (SubscribeResponse) {
    SubscribeResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SubscribeResponse || (SubscribeResponse = {}));
export var TagResourceRequest;
(function (TagResourceRequest) {
    TagResourceRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TagResourceRequest || (TagResourceRequest = {}));
export var TagResourceResponse;
(function (TagResourceResponse) {
    TagResourceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TagResourceResponse || (TagResourceResponse = {}));
export var UnsubscribeInput;
(function (UnsubscribeInput) {
    UnsubscribeInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UnsubscribeInput || (UnsubscribeInput = {}));
export var UntagResourceRequest;
(function (UntagResourceRequest) {
    UntagResourceRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UntagResourceRequest || (UntagResourceRequest = {}));
export var UntagResourceResponse;
(function (UntagResourceResponse) {
    UntagResourceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UntagResourceResponse || (UntagResourceResponse = {}));
var VerificationException = (function (_super) {
    __extends(VerificationException, _super);
    function VerificationException(opts) {
        var _this = _super.call(this, __assign({ name: "VerificationException", $fault: "client" }, opts)) || this;
        _this.name = "VerificationException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, VerificationException.prototype);
        _this.Message = opts.Message;
        _this.Status = opts.Status;
        return _this;
    }
    return VerificationException;
}(__BaseException));
export { VerificationException };
export var VerifySMSSandboxPhoneNumberInput;
(function (VerifySMSSandboxPhoneNumberInput) {
    VerifySMSSandboxPhoneNumberInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(VerifySMSSandboxPhoneNumberInput || (VerifySMSSandboxPhoneNumberInput = {}));
export var VerifySMSSandboxPhoneNumberResult;
(function (VerifySMSSandboxPhoneNumberResult) {
    VerifySMSSandboxPhoneNumberResult.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(VerifySMSSandboxPhoneNumberResult || (VerifySMSSandboxPhoneNumberResult = {}));
