import { EmailService } from "./email-service";

window.addEventListener("DOMContentLoaded", () => {
  const emailService = new EmailService();

  const contactForm = document.querySelector<HTMLFormElement>("#contact-form form");
  contactForm.addEventListener("submit", async (event) => {
    event.preventDefault();
    let email = document.querySelector<HTMLInputElement>("#contact-form-email").value;
    let message = document.querySelector<HTMLInputElement>("#contact-form-message").value;
    let subject = `[Eyevinn Contact Form] <${email}> sent you a message`;
    let mailBody = "";
    mailBody += `From: ${email}\n`;
    mailBody += `User Agent: ${window.navigator.userAgent}\n`;
    mailBody += "\n";
    mailBody += `Message: ${message}\n`;

    const userFeedback = document.querySelector<HTMLParagraphElement>("#contact-form-user-feedback");
    try {
      await emailService.sendEmail("contactform", subject, mailBody);
    } catch(err) {
      console.error(err);
      userFeedback.innerHTML = "There was an issue sending your message, contact webmaster@eyevinn.se if the problem persists.";
    } finally {
      userFeedback.innerHTML = "Message has been sent. We will get back to you shortly";
      contactForm.reset();
    }
  });

  const contactFormContainer = document.querySelector<HTMLDivElement>("#contact-form");
  const contactFormButtonClose = document.querySelector<HTMLButtonElement>("#contact-form-button-close");
  const contactFormButtonOpen = document.querySelector<HTMLSpanElement>("#contact-form-button-open");

  contactFormButtonClose.addEventListener("click", () => {
    contactFormContainer.className = "collapsed";
    contactForm.style.display = "none";
    contactFormButtonOpen.style.display = "block";
  });
  contactFormButtonOpen.addEventListener("click", () => {
    contactFormContainer.className = "expanded";
    contactForm.style.display = "block";
    contactFormButtonOpen.style.display = "none";
  });

  contactFormContainer.className = "collapsed";
  contactForm.style.display = "none";
  contactFormButtonOpen.style.display = "block";
});