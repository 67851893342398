import { __extends } from "tslib";
import { ServiceException as __ServiceException, } from "@aws-sdk/smithy-client";
var SNSServiceException = (function (_super) {
    __extends(SNSServiceException, _super);
    function SNSServiceException(options) {
        var _this = _super.call(this, options) || this;
        Object.setPrototypeOf(_this, SNSServiceException.prototype);
        return _this;
    }
    return SNSServiceException;
}(__ServiceException));
export { SNSServiceException };
